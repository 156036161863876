const NotFound = () => {
    return (
        <div className="big-error-w">
        <h1>
            404
        </h1>
        <h5>
            Page not Found
        </h5>
        <h4>
            Oops, Something went missing...
        </h4>
        </div>
    )
}

export default NotFound;