import { React, useState, useEffect } from "react";
import '../../assets/css/index.css';
import './Table.css';
import TableRow from "./TableRow/TableRow";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase-config';

const Table = (props) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [dataLimit] = useState(10);
    const [pageLimit] = useState(5);
    const [finalValue, setFinalValue] = useState(null);
    const [noDomains, setNoDomains] = useState(false);
    const [pages, setPages] = useState(Math.ceil(Object.entries(finalValue || props.domainList).length / dataLimit));
    const [userToken, setUserToken] = useState();
    const [htaccessLoading, setHtaccessLoading] = useState(false);
    const [sslLoading, setSSLLoading] = useState(false);
    const [indexLoading, setIndexLoading] = useState(false);

    useEffect(()=>{
      setPages(Math.ceil(Object.entries(finalValue || props.domainList).length / dataLimit));
      onAuthStateChanged(auth,(user)=>{
        user.getIdToken(true).then(function(idToken) {
            setUserToken(idToken);
        }).catch(function(error) {
            console.log(error)
        });
    });
    }, [currentPage, pages])

    function getPaginationGroup() {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
    };

    function goToNextPage(){
        setCurrentPage((page) => page + 1);
    };

    function goToPreviousPage() {
        setCurrentPage((page) => page - 1);
    };

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    };

    function getPaginatedData() {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return Object.keys(finalValue || props.domainList).slice(startIndex, endIndex);
    };

    function setSearchValue(value) {
      let searchListArray=[];
      let obj = {};
      Object.keys(props.domainList).map((el) => {
        if(value === '') {
          obj[el] = props.domainList[el];
          searchListArray.push(obj);
        } else if(props.domainList[el].url ? props.domainList[el].url.toLowerCase().includes(value): '') {
          obj[el] = props.domainList[el];
          searchListArray.push(obj);
        }
      })
      setFinalValue(searchListArray[0]);
      if(searchListArray[0] === undefined) {
        setNoDomains(true);
      } else {
        setNoDomains(false);
      }
      setPages(Math.round(Object.keys(searchListArray[0]).length / dataLimit) + 1);
      setCurrentPage(1);
    }

    function triggerLoading(type) {
      switch(type) {
        case 'htaccess':
          props.checkAllDomains('htaccess')
          setHtaccessLoading(true);
          setTimeout(()=>{
            setHtaccessLoading(false);
          }, 60000);
          break;
        case 'ssl':
          props.checkAllDomains('ssl')
          setSSLLoading(true);
          setTimeout(()=>{
            setSSLLoading(false);
          }, 60000);
          break;
        case 'index':
          props.checkAllDomains('index')
          setIndexLoading(true);
          setTimeout(()=>{
            setIndexLoading(false);
          }, 60000);
          break;
        default:
          break;
      }
    }

    if(props.domainList) {
    return (
      <div className="element-box table-element-box">
        <h6 className="form-header">Search...</h6>
        <div className="input-group mb-4">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="os-icon os-icon-search"></i>
            </div>
          </div>
          <input className="form-control" placeholder="Start typing to search..." type="text" onChange={(e)=>setSearchValue(e.target.value.toLowerCase())}/>
        </div>
        <div className="table-responsive">
        <table className="table table-lightborder">
            <thead>
            <tr>
                <th>
                Site
                </th>
                <th className="text-center">
                Testing Reason
                </th>
                <th className="text-center">
                  <div className="thead-container-sync">
                    Htaccess Check 
                    <button className="btn btn-outline-primary" disabled={htaccessLoading} onClick={()=>triggerLoading('htaccess')}><i className={`os-icon os-icon-refresh-cw is-loading-${htaccessLoading}`}></i></button>
                  </div>
                </th>
                <th className="text-center">
                  <div className="thead-container-sync">
                    SSL Check 
                    <button className="btn btn-outline-primary" disabled={sslLoading} onClick={()=>triggerLoading('ssl')}><i className={`os-icon os-icon-refresh-cw is-loading-${sslLoading}`}></i></button>
                  </div>
                </th>
                <th className="text-center">
                  <div className="thead-container-sync">
                    Robots Check 
                    <button className="btn btn-outline-primary" disabled={indexLoading} onClick={()=>triggerLoading('index')}><i className={`os-icon os-icon-refresh-cw is-loading-${indexLoading}`}></i></button>
                  </div>
                </th>
                <th className="text-center">
                Delete
                </th>
            </tr>
            </thead>
            <tbody>
              {noDomains ? (<tr><td>No domains for that search query...</td></tr>) : getPaginatedData().map((site, idx) => (
              <TableRow 
                key={idx}
                url={props.domainList[site] !== undefined ? props.domainList[site].url : undefined} 
                site={site} 
                reason={props.domainList[site] !== undefined ? (props.domainList[site].reason !== undefined ? props.domainList[site].reason.reasonText : ''): ''} 
                checkIfSiteIsAccessible={()=>props.checkIfSiteIsAccessible(props.domainList[site].url, props.domainList[site] !== undefined ? (props.domainList[site].reason !== undefined ? props.domainList[site].reason.reasonText : ''): '')}
                checkSSL={()=>props.checkSSL(props.domainList[site].url, props.domainList[site] !== undefined ? (props.domainList[site].reason !== undefined ? props.domainList[site].reason.reasonText : ''): '')}
                isItIndexed={()=>props.isItIndexed(props.domainList[site].url, props.domainList[site] !== undefined ? (props.domainList[site].reason !== undefined ? props.domainList[site].reason.reasonText : ''): '')}
                deleteDomain={()=>props.deleteDomain(props.domainList[site].url)}
                currentPage={currentPage}
                userToken={userToken}
              />
            ))}
            </tbody>
        </table>
        </div>
        <div className="pagination">
          <button onClick={goToPreviousPage} className={`prev ${currentPage === 1 ? 'disabled' : ''}`}><i className="os-icon os-icon-chevron-left"></i></button>
          {getPaginationGroup().map((item, index) => (
          <button
              key={index}
              onClick={changePage}
              className={`paginationItem ${currentPage === item ? 'active' : null}`}
            ><span>{item}</span>
            </button>
          ))}
          <button
            onClick={goToNextPage}
            className={`next ${currentPage === pages ? 'disabled' : ''}`}
          ><i className="os-icon os-icon-chevron-right"></i></button>
        </div>
        </div>
    )}

    return (
        <div>
            No domains...
        </div>
    )
}

export default Table;