import { useContext, useState } from "react";
import axios from 'axios';
import { AppContext } from "../../../AppContext";

const EditDomain = (props) => {
    const [updatedUrl, setUpdatedUrl] = useState();
    const context = useContext(AppContext)

    function editTheURL(){
        axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains.json?auth=${props.userToken}`).then((response)=> {
        const data = response.data;
        let isInTheList = false;
        Object.entries(data).map((site) => {
            if(site[1].url === updatedUrl) {
                isInTheList = true;
            }
            return true;
            })
          if(updatedUrl.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g) && isInTheList === false){
            axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains/${props.site}.json?auth=${props.userToken}`)
            .then(response => {
                const theData = response.data;
                axios.put(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains/${props.site}.json?auth=${props.userToken}`, {
                    error: {
                        htaccess: {
                          error_on_off: theData.error.htaccess.error_on_off,
                          error_reason: theData.error.htaccess.error_reason
                        },
                        ssl: {
                          error_on_off: theData.error.ssl.error_on_off,
                          error_reason: theData.error.ssl.error_reason
                        },
                        index: {
                          error_on_off: theData.error.index.error_on_off,
                          error_reason: theData.error.index.error_reason
                        }
                      },
                      warning: {
                        htaccess: {
                          warning_on_off: theData.warning.htaccess.warning_on_off,
                          warning_reason: theData.warning.htaccess.warning_reason
                        },
                        ssl: {
                          warning_on_off: theData.warning.ssl.warning_on_off,
                          warning_reason: theData.warning.ssl.warning_reason
                        },
                        index: {
                          warning_on_off: theData.warning.index.warning_on_off,
                          warning_reason: theData.warning.index.warning_reason
                        }
                      },
                      reason: {
                        reasonText: theData.reason.reasonText
                      },
                      testing: {
                        htaccess_enabled: theData.testing.htaccess_enabled,
                        ssl_enabled: theData.testing.ssl_enabled,
                        index_enabled: theData.testing.index_enabled
                      },
                      url: updatedUrl
                })
                if(response.status === 200) {
                    context.setAlertMessage("Domain updated successfully.")
                    setUpdatedUrl("");
                    props.setEditURL(false);
                    context.setDomainUpdated(!context.domainUpdated);
                }
            })
          } else if(updatedUrl === "") {
            context.setAlertMessage("Please enter a URL.");
          } else {
            context.setAlertMessage("The URL entered is either not correct or already on the list.");
          }
    })
}

    return (
        <div className="url-container">
            <input 
                className={`edit-url-input-${props.editURL} form-control`} 
                type="text" 
                placeholder={`Update URL.`} 
                onChange={(e)=>setUpdatedUrl(e.target.value)}/>
            <button className={`btn btn-primary save-url-${props.editURL}`} onClick={()=>editTheURL()}><i className="os-icon os-icon-save"></i></button>
            <i className="os-icon os-icon-link-2"></i> <a href={props.url} target="_blank" rel="noreferrer">{props.url}</a>
        </div>
    )
}

export default EditDomain;