import { Link, Routes, Route } from 'react-router-dom';
import AddDomain from '../Forms/AddDomain/AddDomain';
import './SideMenu.css';

const SideMenu = ({toggle, stateChanger, isLoggedIn }) => {

    return (
        <div id={`side-menu${toggle}`} className="menu-w color-scheme-light color-style-default menu-position-side menu-side-left menu-layout-full sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link">
          <ul className="main-menu">
              <Routes>
                <Route path="/url-checks" element={isLoggedIn ? <AddDomain /> : null} />
              </Routes>
            <li className="sub-header">
              <span>Main Area</span>
            </li>
            <li className="selected has-sub-menu">
              <Link to="/" onClick={()=>stateChanger(!toggle)} className={`enabled-${isLoggedIn}`}>
                <div className="icon-w">
                  <div className="os-icon os-icon-layout"></div>
                </div>
                <span>Dashboard</span>
              </Link>
              <div className="sub-menu-w">
                <div className="sub-menu-header">
                  Dashboard
                </div>
              </div>
            </li>
            <li className="sub-header">
              <span>Tools</span>
            </li>
            <li className="has-sub-menu">
              <Link to="/url-checks" onClick={()=>stateChanger(!toggle)} className={`enabled-${isLoggedIn}`}>
                <div className="icon-w">
                  <div className="os-icon os-icon-package"></div>
                </div>
                <span>Htaccess & SSL Check</span>
              </Link>
            </li>
            <li className="has-sub-menu">
              <Link to="/projects" onClick={()=>stateChanger(!toggle)} className={`enabled-${isLoggedIn}`}>
                <div className="icon-w">
                  <div className="os-icon os-icon-zap"></div>
                </div>
                <span>Performance Testing</span>
              </Link>
            </li>
          </ul>
        </div>
    )
}

export default SideMenu;