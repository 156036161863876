import './TestLog.css';

const TestLog = (props) => {
    var theDate = new Date(props.logTimestamp);
    return (
        <tr>
            <td>
            <div>
                <span>{theDate.toLocaleDateString()}</span><span className="smaller lighter"> {theDate.toLocaleTimeString()}</span>
            </div>
            </td>
            <td className="text-left nowrap">
                <div className={`result-container ${props.mobilePerformanceScore && props.mobilePerformanceScore >= 0.8 ? 'good-score' : (props.mobilePerformanceScore >= 0.6 && props.mobilePerformanceScore <= 0.79 ? 'medium-score' : 'low-score')}`}>
                    <i className="os-icon os-icon-tablet"></i> {props.mobilePerformanceScore ? ((props.mobilePerformanceScore * 100).toFixed(0) + ' / 100') : 'N/A'}
                </div>
                <div className={`result-container ${props.desktopPerformanceScore && props.desktopPerformanceScore >= 0.8 ? 'good-score' : (props.desktopPerformanceScore >= 0.6 && props.desktopPerformanceScore <= 0.79 ? 'medium-score' : 'low-score')}`}>
                    <i className="os-icon os-icon-monitor"></i> {props.desktopPerformanceScore ? ((props.desktopPerformanceScore * 100).toFixed(0) + ' / 100') : 'N/A'}
                </div>
            </td>
            <td className="text-left nowrap">
                <div className={`result-container ${props.mobileCLS && props.mobileCLS.score >= 0.8 ? 'good-score' : (props.mobileCLS.score >= 0.6 && props.mobileCLS.score <= 0.79 ? 'medium-score' : 'low-score')}`}><i className="os-icon os-icon-tablet"></i> {props.mobileCLS.value}</div>
                <div className={`result-container ${props.desktopCLS && props.desktopCLS.score >= 0.8 ? 'good-score' : (props.desktopCLS.score >= 0.6 && props.desktopCLS.score <= 0.79 ? 'medium-score' : 'low-score')}`}><i className="os-icon os-icon-monitor"></i> {props.desktopCLS.value}</div>
            </td>
            <td className="text-left nowrap">
                <div className={`result-container ${props.mobileLCP && props.mobileLCP.score >= 0.8 ? 'good-score' : (props.mobileLCP.score >= 0.6 && props.mobileLCP.score <= 0.79 ? 'medium-score' : 'low-score')}`}><i className="os-icon os-icon-tablet"></i> {props.mobileLCP.value}</div>
                <div className={`result-container ${props.desktopLCP && props.desktopLCP.score >= 0.8 ? 'good-score' : (props.desktopLCP.score >= 0.6 && props.desktopLCP.score <= 0.79 ? 'medium-score' : 'low-score')}`}><i className="os-icon os-icon-monitor"></i> {props.desktopLCP.value}</div>
            </td>
            <td className="text-left nowrap">
                <div className={`result-container ${props.mobileFCP && props.mobileFCP.score >= 0.8 ? 'good-score' : (props.mobileFCP.score >= 0.6 && props.mobileFCP.score <= 0.79 ? 'medium-score' : 'low-score')}`}><i className="os-icon os-icon-tablet"></i> {props.mobileFCP.value}</div>
                <div className={`result-container ${props.desktopFCP && props.desktopFCP.score >= 0.8 ? 'good-score' : (props.desktopFCP.score >= 0.6 && props.desktopFCP.score <= 0.79 ? 'medium-score' : 'low-score')}`}><i className="os-icon os-icon-monitor"></i> {props.desktopFCP.value}</div>
            </td>
            <td className="text-left nowrap">
                <div className={`result-container ${props.mobileTbtScore && props.mobileTbtScore.score >= 0.8 ? 'good-score' : (props.mobileTbtScore.score >= 0.6 && props.mobileTbtScore.score <= 0.79 ? 'medium-score' : 'low-score')}`}><i className="os-icon os-icon-tablet"></i> {props.mobileTbtScore.value}</div>
                <div className={`result-container ${props.desktopTbtScore && props.desktopTbtScore.score >= 0.8 ? 'good-score' : (props.desktopTbtScore.score >= 0.6 && props.desktopTbtScore.score <= 0.79 ? 'medium-score' : 'low-score')}`}><i className="os-icon os-icon-monitor"></i> {props.desktopTbtScore.value}</div>
            </td>
            <td className="text-left nowrap">
                <div className={`result-container ${props.mobileTtiScore && props.mobileTtiScore.score >= 0.8 ? 'good-score' : (props.mobileTtiScore.score >= 0.6 && props.mobileTtiScore.score <= 0.79 ? 'medium-score' : 'low-score')}`}><i className="os-icon os-icon-tablet"></i> {props.mobileTtiScore.value}</div>
                <div className={`result-container ${props.desktopTtiScore && props.desktopTtiScore.score >= 0.8 ? 'good-score' : (props.desktopTtiScore.score >= 0.6 && props.desktopTtiScore.score <= 0.79 ? 'medium-score' : 'low-score')}`}><i className="os-icon os-icon-monitor"></i> {props.desktopTtiScore.value}</div>
            </td>
            <td className="text-left nowrap">
                <div className={`result-container ${props.mobileSpeedIndex && props.mobileSpeedIndex.score >= 0.8 ? 'good-score' : (props.mobileSpeedIndex.score >= 0.6 && props.mobileSpeedIndex.score <= 0.79 ? 'medium-score' : 'low-score')}`}><i className="os-icon os-icon-tablet"></i> {props.mobileSpeedIndex.value}</div>
                <div className={`result-container ${props.desktopSpeedIndex && props.desktopSpeedIndex.score >= 0.8 ? 'good-score' : (props.desktopSpeedIndex.score >= 0.6 && props.desktopSpeedIndex.score <= 0.79 ? 'medium-score' : 'low-score')}`}><i className="os-icon os-icon-monitor"></i> {props.desktopSpeedIndex.value}</div>
            </td>
            <td className="text-left nowrap">
                <div className='result-container'><i className="os-icon os-icon-tablet"></i>
                    <div className='result-container'><span>{props.mobileLoadingExperienceOverall ? props.mobileLoadingExperienceOverall : 'N/A'}</span><span className={`status-pill ${props.mobileLoadingExperienceOverall === 'AVERAGE' ? 'yellow' : (props.mobileLoadingExperienceOverall === 'FAST' ? 'green' : 'red')}`}></span></div>
                </div>
                <div className='result-container'><i className="os-icon os-icon-monitor"></i>
                    <div className='result-container'><span>{props.desktopLoadingExperienceOverall ? props.desktopLoadingExperienceOverall : 'N/A'}</span><span className={`status-pill ${props.desktopLoadingExperienceOverall === 'AVERAGE' ? 'yellow' : (props.desktopLoadingExperienceOverall === 'FAST' ? 'green' : 'red')}`}></span></div>
                </div>
            </td>
        </tr>
    )
}

export default TestLog;