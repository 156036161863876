import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import TestLog from "../../components/LogComponent/TestLog";
import './DomainPage.css';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase-config';
import { Navigate } from 'react-router-dom';
import TestingSchedule from "../../components/TestingSchedule/TestingSchedule";
import PerformanceChart from "../../components/PerformanceChart/PerformanceChart";
import Loader from '../../components/Loader/Loader';

const DomainPage = () => {
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const { state } = useLocation();
    const [testing, setTesting] = useState(false);
    const [testResults, setTestResults] = useState();
    const [userToken, setUserToken] = useState();
    const [testRun, setTestRun] = useState(false);
    const [testLogs, setLogs] = useState(undefined);
    const [mobileChartData, setMobileChartData] = useState();
    const [desktopChartData, setDesktopChartData] = useState();
    const [loadChart, setLoadChart] = useState(false);
    const [dataView, setDataView] = useState('mobile');
    const [performanceAttribute, setAttribute] = useState('Performance');

    const deleteDomain = () => {
        axios.delete(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/projects/${state.siteId}/domains/${state.projectId}.json?auth=${userToken}`)
        .then(response => {
            context.setAlertMessage('Domain deleted!');
            navigate(-1)
        });
    }

    const runTest = () => {
        setTesting(true);
        context.setAlertMessage("Performance test running...");
        setTestRun(!testRun);
        setTestResults();
        const timer = setTimeout(()=>{
            axios.get(`https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${state.projectName}&category=PERFORMANCE&strategy=MOBILE&key=AIzaSyD9cMvyb69hnXGZoliDaxLgJqhFROMfqgc`)
            .then(response => {
                if(response){
                    let data = response.data;
                    axios.get(`https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${state.projectName}&category=PERFORMANCE&strategy=DESKTOP&key=AIzaSyD9cMvyb69hnXGZoliDaxLgJqhFROMfqgc`)
                    .then(res => {
                        if(res) {
                            setTesting(false);
                            let desktopData = res.data;
                            axios.post(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/projects/${state.siteId}/domains/${state.projectId}/logs.json?auth=${userToken}`, {
                                logTimestamp: data.analysisUTCTimestamp,
                                desktopPerformanceScore: desktopData.lighthouseResult.categories.performance.score,
                                desktopLoadTime: desktopData.lighthouseResult.timing.total,
                                desktopLoadingExperienceOverall: desktopData.loadingExperience.overall_category,
                                desktopFcpScore: {
                                    value: desktopData.lighthouseResult.audits['first-contentful-paint'].displayValue,
                                    score: desktopData.lighthouseResult.audits['first-contentful-paint'].score,
                                },
                                desktopLcpScore: {
                                    value: desktopData.lighthouseResult.audits['largest-contentful-paint'].displayValue,
                                    score: desktopData.lighthouseResult.audits['largest-contentful-paint'].score,
                                },
                                desktopClsScore: {
                                    value: desktopData.lighthouseResult.audits['cumulative-layout-shift'].displayValue,
                                    score: desktopData.lighthouseResult.audits['cumulative-layout-shift'].score,
                                },
                                desktopTbtScore: {
                                    value: desktopData.lighthouseResult.audits['total-blocking-time'].displayValue,
                                    score: desktopData.lighthouseResult.audits['total-blocking-time'].score,
                                },
                                desktopSpeedIndex: {
                                    value: desktopData.lighthouseResult.audits['speed-index'].displayValue,
                                    score: desktopData.lighthouseResult.audits['speed-index'].score,
                                },
                                desktopTtiScore: {
                                    value: desktopData.lighthouseResult.audits['interactive'].displayValue,
                                    score: desktopData.lighthouseResult.audits['interactive'].score,
                                },
                                mobilePerformanceScore: data.lighthouseResult.categories.performance.score,
                                mobileLoadTime: data.lighthouseResult.timing.total,
                                mobileLoadingExperienceOverall: data.loadingExperience.overall_category,
                                mobileFcpScore: {
                                    value: data.lighthouseResult.audits['first-contentful-paint'].displayValue,
                                    score: data.lighthouseResult.audits['first-contentful-paint'].score,
                                },
                                mobileLcpScore: {
                                    value: data.lighthouseResult.audits['largest-contentful-paint'].displayValue,
                                    score: data.lighthouseResult.audits['largest-contentful-paint'].score,
                                },
                                mobileClsScore: {
                                    value: data.lighthouseResult.audits['cumulative-layout-shift'].displayValue,
                                    score: data.lighthouseResult.audits['cumulative-layout-shift'].score,
                                },
                                mobileTbtScore: {
                                    value: data.lighthouseResult.audits['total-blocking-time'].displayValue,
                                    score: data.lighthouseResult.audits['total-blocking-time'].score,
                                },
                                mobileSpeedIndex: {
                                    value: data.lighthouseResult.audits['speed-index'].displayValue,
                                    score: data.lighthouseResult.audits['speed-index'].score,
                                },
                                mobileTtiScore: {
                                    value: data.lighthouseResult.audits['interactive'].displayValue,
                                    score: data.lighthouseResult.audits['interactive'].score,
                                }
                            })
                            .then(response => {
                                desktopData = '';
                                data = '';
                                if(response) {
                                    setTestRun(!testRun);
                                }
                            })
                        }
                        if(res.status !== 200) {
                            setTesting(false);
                        }
                    })
                    if(response.status !== 200) {
                        setTesting(false);
                    }
                }
            })
            .catch(error => {
                context.setAlertMessage(`${error}`);
                setTesting(false);
            })
        }, 3000)

        return () => {
            clearTimeout(timer);
        }
    }

    useEffect(()=>{
        onAuthStateChanged(auth,(user)=>{
            user.getIdToken(true).then(function(idToken) {
                setUserToken(idToken);
                setLogs([]);
                axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/projects/${state.siteId}/domains/${state.projectId}/logs.json?auth=${idToken}`)
                .then(response => {
                    setLogs(response.data);
                    let desktopData = [['Date', performanceAttribute]];
                    let mobileData = [['Date', performanceAttribute]];
                    Object.entries(response.data).map((item) => {
                        let theDate = new Date(item[1].logTimestamp);
                        if(performanceAttribute === 'Performance') {
                            mobileData.push([theDate.toLocaleDateString(), Number((item[1].mobilePerformanceScore * 100).toFixed(0))])
                            desktopData.push([theDate.toLocaleDateString(),  Number((item[1].desktopPerformanceScore * 100).toFixed(0))]);    
                        }
                        if(performanceAttribute === 'CLS') {
                            mobileData.push([theDate.toLocaleDateString(), Number(item[1].mobileClsScore.value)])
                            desktopData.push([theDate.toLocaleDateString(),  Number(item[1].desktopClsScore.value)]);    
                        }
                        if(performanceAttribute === 'LCP') {
                            mobileData.push([theDate.toLocaleDateString(), Number(item[1].mobileLcpScore.value.replace("s", ""))])
                            desktopData.push([theDate.toLocaleDateString(),  Number(item[1].desktopLcpScore.value.replace("s", ""))]);    
                        }
                        if(performanceAttribute === 'FCP') {
                            mobileData.push([theDate.toLocaleDateString(),  Number(item[1].mobileFcpScore.value.replace("s", ""))])
                            desktopData.push([theDate.toLocaleDateString(),  Number(item[1].desktopFcpScore.value.replace("s", ""))]);    
                        }
                        if(performanceAttribute === 'TBT') {
                            mobileData.push([theDate.toLocaleDateString(), Number(item[1].mobileTbtScore.value.replace("ms", ""))])
                            desktopData.push([theDate.toLocaleDateString(),  Number(item[1].desktopTbtScore.value.replace("ms", ""))]);    
                        }
                        if(performanceAttribute === 'TTI') {
                            mobileData.push([theDate.toLocaleDateString(), Number(item[1].mobileTtiScore.value.replace("s", ""))])
                            desktopData.push([theDate.toLocaleDateString(),  Number(item[1].desktopTtiScore.value.replace("s", ""))]);    
                        }
                        if(performanceAttribute === 'Speed Index') {
                            mobileData.push([theDate.toLocaleDateString(), Number(item[1].mobileSpeedIndex.value.replace("s", ""))])
                            desktopData.push([theDate.toLocaleDateString(),  Number(item[1].desktopSpeedIndex.value.replace("s", ""))]);    
                        }
                    });
                    setMobileChartData(mobileData);
                    setDesktopChartData(desktopData);
                    setLoadChart(true);
            }).catch(function(error) {
                //
            });
        });
    });
    }, [testing, dataView, performanceAttribute])

    if(state === null) {
        return <Navigate to='/projects' />
    }
    return (
        <div className="domain-container">
            <div style={{width: "100%"}} className="element-wrapper mt-2 pb-0 domain-page-header">
                <div className='project-dashboard-header-container'>
                    <div>
                        <h6 className="dashboard-h6">
                            Domain Performance Testing Dashboard
                        </h6>
                        <h3 className="project-name-header domain-header element-header ml-2 mb-2">{state && state.projectName ? state.projectName : ''} <button onClick={()=>deleteDomain()}className="mr-2 mb-2 btn btn-outline-danger" type="button"><i className="os-icon os-icon-trash-2"></i></button> </h3>
                    </div>
                </div>
                <div className="domain-buttons">
                    <button onClick={()=>runTest()} className={`mr-2 mb-2 btn btn-primary button-testing-${testing}`} type="button">
                        <i className={`testing-${testing} os-icon os-icon-refresh-cw is-loading-${testing}`}></i>
                        <span className={`text-testing-${testing}`}>Run Test</span>
                    </button>
                </div>
            </div>
            <div className="element-wrapper test-logs-container">
            <h4 className="element-header logs-heading">
                Schedule
            </h4>
            <TestingSchedule state={state}/>
            <div className="chart-select-forms">
                <select className="form-control form-control-sm mb-2" value={dataView} onChange={(e)=>setDataView(e.target.value)}>
                    <option value="mobile">Mobile</option>
                    <option value="desktop">Desktop</option>
                </select>
                <select className="form-control form-control-sm mb-2" value={performanceAttribute} onChange={(e)=>setAttribute(e.target.value)}>
                    <option value="Performance">Performance</option>
                    <option value="CLS">CLS</option>
                    <option value="LCP">LCP</option>
                    <option value="FCP">FCP</option>
                    <option value="TBT">TBT</option>
                    <option value="TTI">TTI</option>
                    <option value="Speed Index">Speed Index</option>
                </select>
            </div>
            {(mobileChartData && mobileChartData.length > 1) && (desktopChartData && desktopChartData.length > 1) ? 
                (loadChart ?
                <PerformanceChart data={dataView === 'mobile' ? mobileChartData : desktopChartData}/> : 
                <Loader/>
                )
            : <p className='no-test-notice'>Please run some test to view the performance statistics for the domain!</p>}
            <br></br>
            <h4 className="element-header logs-heading">
                Recent Logs
            </h4>
            <div className="element-box-tp">
                <div className="table-responsive">
                <table className="table table-padded">
                    <thead>
                    <tr>
                        <th className="text-left">
                        Date
                        </th>
                        <th className="text-left">
                        Performance Score
                        </th>
                        <th className="text-left">
                        Cumulative Layout Shift
                        </th>
                        <th className="text-left">
                        Largest Contentful Paint
                        </th>
                        <th className="text-left">
                        First Contentful Paint
                        </th>
                        <th className="text-left">
                        Total Blocking Time
                        </th>
                        <th className="text-left">
                        Time to Interactive
                        </th>
                        <th className="text-left">
                        Speed Index
                        </th>
                        <th className="text-left">
                        Loading Experience
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {testLogs !== undefined && testLogs ? Object.entries(testLogs).reverse().map((item, idx) => {
                        return (
                            <TestLog
                                key={idx}
                                mobileCLS={item[1].mobileClsScore}
                                mobileFCP={item[1].mobileFcpScore}
                                mobileLCP={item[1].mobileLcpScore}
                                mobileLoadTime={item[1].mobileLoadTime}
                                mobileLoadingExperienceOverall={item[1].mobileLoadingExperienceOverall}
                                logTimestamp={item[1].logTimestamp}
                                mobilePerformanceScore={item[1].mobilePerformanceScore}
                                mobileSpeedIndex={item[1].mobileSpeedIndex}
                                mobileTbtScore={item[1].mobileTbtScore}
                                mobileTtiScore={item[1].mobileTtiScore}
                                desktopCLS={item[1].desktopClsScore}
                                desktopFCP={item[1].desktopFcpScore}
                                desktopLCP={item[1].desktopLcpScore}
                                desktopLoadTime={item[1].desktopLoadTime}
                                desktopLoadingExperienceOverall={item[1].desktopLoadingExperienceOverall}
                                desktopPerformanceScore={item[1].desktopPerformanceScore}
                                desktopSpeedIndex={item[1].desktopSpeedIndex}
                                desktopTbtScore={item[1].desktopTbtScore}
                                desktopTtiScore={item[1].desktopTtiScore}
                            />
                        )
                    }) : <tr></tr>}
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </div>
    )
}

export default DomainPage;