import Table from "../../components/Table/Table";
import { AppContext } from "../../AppContext";
import { useContext, useState, useEffect } from "react";
import Loader from '../../components/Loader/Loader.js';
import axios from 'axios';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase-config';
import Dashboard from "../Dashboard/Dashboard";

const CheckDashboard = () => {
    const context = useContext(AppContext);
    const [domainList, setDomainList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userToken, setUserToken] = useState();

    useEffect(()=>{
        onAuthStateChanged(auth,(user)=>{
            user.getIdToken(true).then(function(idToken) {
                setUserToken(idToken);
                axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains.json?auth=${idToken}`).then((response)=> {
                const data = response.data;
                setDomainList(data);
                if(context.domainAdded) {
                    setLoading(true);
                    context.setDomainAdded(false);
                }
                setLoading(false);
                context.setLoading(false);
                setLoading(false)
                context.setNumberOfDomains(domainList.length);
                });
            }).catch(function(error) {
                //
            });
        });
      }, [context.loading, loading, context.domainAdded, context.domainUpdated]);

    function deleteDomain(websiteURL){
        Object.entries(domainList).map((site) => {
        if(site[1].url === websiteURL) {
            axios.delete(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains/${site[0]}.json?auth=${userToken}`);
                context.setAlertMessage("Website deleted successfully!");
            }
        });
        context.setLoading(true);
    }

    function checkSSL(websiteURL, reason){
        axios.get(`https://devbot-server.herokuapp.com/ssl?url=${websiteURL}${reason === '' ? '' : `&reason=${reason}`}`).then(response=>{
            context.setAlertMessage(response.data);
        }).catch((error) => {
            context.setAlertMessage("Website can't be reached!");
        });
    }
      
    function checkIfSiteIsAccessible(websiteURL, reason) {
        context.setAlertMessage("Sent request.")
        axios.get(`https://devbot-server.herokuapp.com/htaccess?url=${websiteURL}${reason === '' ? '' : `&reason=${reason}`}`).then(response=>{
            context.setAlertMessage(response.data);
        }).catch((error) => {
            context.setAlertMessage("Website can't be reached!");
        });
    }
      
    function isItIndexed(websiteURL, reason) {
        context.setAlertMessage("Sent request.");
        axios.get(`https://devbot-server.herokuapp.com/index?url=${websiteURL}${reason === '' ? '' : `&reason=${reason}`}`).then(response=>{
            context.setAlertMessage(response.data);
        }).catch((error) => {
            context.setAlertMessage("Website can't be reached!");
        });
    }

    function checkAllDomains(type) {
        axios.get(`https://devbot-server.herokuapp.com/run-test?type=${type}`)
        .then(response => {
            context.setAlertMessage(response.data);
        })
    }

    return (
        <div className="domain-container">
            <Dashboard title={'General Information'}/>
            <div style={{width: "100%"}} className="element-wrapper mt-2 pb-0">
                <div>
                    <h6 className="element-header ml-2 mb-2">
                    List of Domains
                    </h6>
                </div>
            </div>
            {loading ? 
            <Loader /> : 
            <Table 
                deleteDomain={deleteDomain}
                checkSSL={checkSSL}
                checkIfSiteIsAccessible={checkIfSiteIsAccessible}
                isItIndexed={isItIndexed}
                domainList={domainList}
                checkAllDomains={checkAllDomains}
            />
            }
        </div>
    )

}

export default CheckDashboard;