import React, {useContext, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import { auth } from '../../../firebase-config';
import { createUserWithEmailAndPassword } from "firebase/auth";
import './Register.css';

const Register = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const context = useContext(AppContext);
    const navigate = useNavigate();

    function validatePassword(){
        let isValid = true
        const check_email = '[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](sherocommerce.com)';
        let pattern = new RegExp(check_email);
        if(pattern.test(email)){
          if (password !== '' && confirmPassword !== ''){
            if (password !== confirmPassword) {
            isValid = false
            context.setAlertMessage('Passwords do not match!')
            setPassword('');
            setConfirmPassword('');
            }
          }
        return isValid
        } else {
          setEmail('');
          context.setAlertMessage('Please use an email from the Shero Commerce organization.')
        }
    }

    function register(e){
      e.preventDefault();
      if(validatePassword()) {
        createUserWithEmailAndPassword(auth, email, password)
        .then((res) => {
            navigate('/');
          })
        .catch(err => context.setAlertMessage(err.message));
      setEmail('')
      setPassword('')
      setConfirmPassword('')
      }
    }

    return (
        <div className="auth-box-w">
        <div className="logo-w">
        <i className="logo-register-form os-icon os-icon-robot-2"></i>
        </div>
        <h4 className="auth-header">
          Register Form
        </h4>
        <form onSubmit={register}>
          <div className="form-group">
            <label htmlFor="">Email</label>
            <input 
              className="form-control" 
              placeholder="Enter your email"
              type="text"
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
            />
            <div className="pre-icon os-icon os-icon-user-male-circle"></div>
          </div>
          <div className="form-group">
            <label htmlFor="">Password</label>
            <input 
              className="form-control" 
              placeholder="Enter your password" 
              type="password"
              value={password}
              required
              onChange={e => setPassword(e.target.value)}
            />
            <div className="pre-icon os-icon os-icon-fingerprint"></div>
          </div>
          <div className="form-group">
            <label htmlFor="">Repeat Password</label>
            <input 
              className="form-control" 
              placeholder="Enter your password" 
              type="password"
              value={confirmPassword}
              required
              onChange={e => setConfirmPassword(e.target.value)}
            />
            <div className="pre-icon os-icon os-icon-fingerprint"></div>
          </div>
          <div className="buttons-w">
            <button className="btn btn-primary" type="submit">Register</button>
          </div>
          <Link to="/login">
            <p className="register-link">Have an account? Login here</p>
          </Link>
        </form>
      </div>
    );

}

export default Register;