import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase-config';
import { AppContext } from '../../AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import './ProjectPage.css';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import PerformanceReport from '../../components/PerformanceReport/PerformanceReport';
import PerformanceChart from '../../components/PerformanceChart/PerformanceChart';
import Loader from '../../components/Loader/Loader';

const ProjectPage = (props) => {
    const { state } = useLocation();
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const [newDomain, setNewDomain] = useState('');
    const [updatePage, setUpdatePage] = useState(false);
    const [userToken, setUserToken] = useState('');
    const [domains, setDomains] = useState(null);
    const [mobileChartData, setMobileChartData] = useState();
    const [desktopChartData, setDesktopChartData] = useState();
    const [loadChart, setLoadChart] = useState(false);
    const [dataView, setDataView] = useState('mobile');
    const [performanceAttribute, setAttribute] = useState('Performance');

    const deleteProject = () => {
        axios.delete(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/projects/${state.siteId}.json?auth=${userToken}`)
        .then(response => {
            context.setAlertMessage('Project deleted!');
            navigate(-1)
        });
    }

    const addNewDomain = () => {
        if(newDomain !== '' && newDomain !== undefined && newDomain !== null) {
            let isInTheList = false;
            axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/projects/${state.siteId}/domains.json?auth=${userToken}`).then((response)=> {
            const data = response.data;
            if(data !== null && data !== undefined) {
                Object.entries(data).map((site) => {
                    if(site[1].url === newDomain) {
                        isInTheList = true;
                    }
                    return true;
                    })
            }
                if(newDomain.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g) && isInTheList === false){
                    axios.post(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/projects/${state.siteId}/domains.json?auth=${userToken}`, {
                        url: newDomain,
                        logs: [],
                    })
                    .then(response => {
                        setNewDomain('');
                        setUpdatePage(!updatePage);
                        context.setAlertMessage('Domain added successfully.')
                    })
                } else {
                    context.setAlertMessage("The URL entered is either not correct or already on the list.");
                }
            })
        } else {
            context.setAlertMessage('Please enter a domain for the project.')
        }
    }

    const onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        onAuthStateChanged(auth,(user)=>{
            user.getIdToken(true).then(function(idToken) {
                setUserToken(idToken);
                axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/projects/${state.siteId}/domains.json?auth=${idToken}`).then((response)=> {
                    const data = response.data;
                    let desktopData = [['Date', performanceAttribute]];
                    let mobileData = [['Date', performanceAttribute]];
                    let arrayOfTimestamps = [];
                    let arrayOfLogs = [];
                    Object.entries(data).map(domain => {
                        if(domain[1].logs !== undefined) {
                            Object.entries(domain[1].logs).map(log => {
                                let theDate = new Date(log[1].logTimestamp)
                                arrayOfTimestamps.push(theDate.toLocaleDateString());
                                arrayOfLogs.push(log[1]);
                            })
                        }
                    })
                    let uniqueArray = arrayOfTimestamps.filter(onlyUnique);
                    arrayOfLogs.map(log => {
                        let theDate = new Date(log.logTimestamp)
                        uniqueArray.map(date => {
                            if(date === theDate.toLocaleDateString()) {
                                arrayOfLogs.pop(log)
                            }
                        })
                    })

                    arrayOfLogs
                    .sort(function(x,y){
                        let theDateX = new Date(x.logTimestamp);
                        let theDateY = new Date(y.logTimestamp);
                        return theDateX.getDay() - theDateY.getDay()
                    })
                    .map(item => {
                        let theDate = new Date(item.logTimestamp)
                        if(performanceAttribute === 'Performance') {
                            mobileData.push([theDate.toLocaleDateString(), Number((item.mobilePerformanceScore * 100).toFixed(0))])
                            desktopData.push([theDate.toLocaleDateString(),  Number((item.desktopPerformanceScore * 100).toFixed(0))]);    
                        }
                        if(performanceAttribute === 'CLS') {
                            mobileData.push([theDate.toLocaleDateString(), Number(item.mobileClsScore.value)])
                            desktopData.push([theDate.toLocaleDateString(),  Number(item.desktopClsScore.value)]);    
                        }
                        if(performanceAttribute === 'LCP') {
                            mobileData.push([theDate.toLocaleDateString(), Number(item.mobileLcpScore.value.replace("s", ""))])
                            desktopData.push([theDate.toLocaleDateString(),  Number(item.desktopLcpScore.value.replace("s", ""))]);    
                        }
                        if(performanceAttribute === 'FCP') {
                            mobileData.push([theDate.toLocaleDateString(),  Number(item.mobileFcpScore.value.replace("s", ""))])
                            desktopData.push([theDate.toLocaleDateString(),  Number(item.desktopFcpScore.value.replace("s", ""))]);    
                        }
                        if(performanceAttribute === 'TBT') {
                            mobileData.push([theDate.toLocaleDateString(), Number(item.mobileTbtScore.value.replace("ms", ""))])
                            desktopData.push([theDate.toLocaleDateString(),  Number(item.desktopTbtScore.value.replace("ms", ""))]);    
                        }
                        if(performanceAttribute === 'TTI') {
                            mobileData.push([theDate.toLocaleDateString(), Number(item.mobileTtiScore.value.replace("s", ""))])
                            desktopData.push([theDate.toLocaleDateString(),  Number(item.desktopTtiScore.value.replace("s", ""))]);    
                        }
                        if(performanceAttribute === 'Speed Index') {
                            mobileData.push([theDate.toLocaleDateString(), Number(item.mobileSpeedIndex.value.replace("s", ""))])
                            desktopData.push([theDate.toLocaleDateString(),  Number(item.desktopSpeedIndex.value.replace("s", ""))]);    
                        }
                    })
                    
                    setMobileChartData(mobileData);
                    setDesktopChartData(desktopData);
                    setLoadChart(true);
                    setDomains(Object.entries(data));
                });
            }).catch(function(error) {
                //
            });
        });
    }, [updatePage, dataView, performanceAttribute]);

    return (
        <div className="domain-container">
            <div style={{width: "100%"}} className="element-wrapper mt-2 pb-0">
                <div className='project-dashboard-header-container'>
                    <div>
                        <h6 className="dashboard-h6">
                            Project Dashboard
                        </h6>
                        <h3 className="project-name-header domain-header element-header ml-2 mb-2">{state.projectName} <button onClick={()=>deleteProject()}className="mr-2 mb-2 btn btn-outline-danger" type="button"><i className="os-icon os-icon-trash-2"></i></button></h3>
                    </div>
                    <div>
                        <input className="form-control" placeholder="Add domain to project" type="text" value={newDomain} onChange={e=>setNewDomain(e.target.value)}/>
                        <button className="btn btn-primary" onClick={()=>addNewDomain()}><i className="os-icon os-icon-ui-22"></i><span>Add domain</span></button>
                    </div>
                </div>
            </div>
            { (mobileChartData && mobileChartData.length > 1) && (desktopChartData && desktopChartData.length > 1) ?
            <>
                <div className='report-row'>
                    <PerformanceReport state={state} device="Desktop"></PerformanceReport>
                    <PerformanceReport state={state} device="Mobile"></PerformanceReport>
                </div>
                <div className='container-chart'>
                    <div className="chart-select-forms">
                        <select className="form-control form-control-sm mb-2" value={dataView} onChange={(e)=>setDataView(e.target.value)}>
                            <option value="mobile">Mobile</option>
                            <option value="desktop">Desktop</option>
                        </select>
                        <select className="form-control form-control-sm mb-2" value={performanceAttribute} onChange={(e)=>setAttribute(e.target.value)}>
                            <option value="Performance">Performance</option>
                            <option value="CLS">CLS</option>
                            <option value="LCP">LCP</option>
                            <option value="FCP">FCP</option>
                            <option value="TBT">TBT</option>
                            <option value="TTI">TTI</option>
                            <option value="Speed Index">Speed Index</option>
                        </select>
                    </div>
                    <PerformanceChart data={dataView === 'mobile' ? mobileChartData : desktopChartData}/>
                </div>
            </> :
            <p className='no-test-notice'>Please run some test to view the performance statistics for the project!</p>
            }
            <div className='projects-list'>
                {domains && domains !== null ? 
                domains.map((domain, idx)=>{
                    return (
                        <ProjectCard
                            key={idx}
                            projectName={domain[1].url}
                            projectId={domain[0]}
                            siteId={state.siteId}
                            projectHostName={new URL(domain[1].url).hostname}
                            class="domain-card"/>
                    )
                }) : 
                ''}
            </div>
        </div>
    )
}

export default ProjectPage;