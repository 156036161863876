import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import './Alerts.css';

const Alert = ({theClass, message}) => {
    const [theToggle, setTheToggle] = useState(false);
    const context = useContext(AppContext);


    useEffect(()=>{
        if(message !== ''){
            setTheToggle(true);
            setTimeout(()=>{
                context.setAlertMessage('');
                setTheToggle(false);
            }, 7000)
        }
        clearTimeout();
    }, [message])

    return (
        <div id="alert-container" className={`alert alert-warning show-alert-${theToggle} ${theClass}`} role="alert">
            <span id="feedback">{message}</span>
            <button className="clear-notification" onClick={()=>setTheToggle(false)}><i className="os-icon os-icon-x-circle"></i></button>
        </div>
    )
}

export default Alert;