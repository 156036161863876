import './InfoBox.css';
import { Link } from 'react-router-dom';

const InfoBox = ({boxTitle, type, nrOfDomains, linkTo}) => {
    return (
        <Link to={`${linkTo}`} className={`element-box el-tablo centered trend-in-corner padded bold-label dashboard-box ${type}-class`}>
            <div className="value">
                {nrOfDomains}
            </div>
            <div className="label">
                {boxTitle}
            </div>
        </Link>
    )
}

export default InfoBox;