import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Redirected = () => {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(5);

    useEffect(()=>{
        let countdown = setInterval(()=>{
            setTimer(timer-1);
        }, 1000)
        if(timer === 0) {
            clearInterval(countdown);
            navigate('/');
        }
    })
    return (
        <div className="big-error-w">
            <h2>
                Redirecting...
            </h2>

            <h5>
                You will be automatically redirected to the homepage in {timer} seconds.
            </h5>
            <p>
                Please click <Link to="/">here</Link> if you are not redirected automatically.
            </p>
        </div>
    )
}

export default Redirected;