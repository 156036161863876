import '../../assets/bower_components/select2/dist/css/select2.min.css';
import '../../assets/bower_components/bootstrap-daterangepicker/daterangepicker.css';
import '../../assets/bower_components/dropzone/dist/dropzone.css';
import '../../assets/bower_components/datatables.net-bs/css/dataTables.bootstrap.min.css';
import '../../assets/bower_components/fullcalendar/dist/fullcalendar.min.css';
import '../../assets/bower_components/perfect-scrollbar/css/perfect-scrollbar.min.css';
import '../../assets/bower_components/slick-carousel/slick/slick.css';
import '../../assets/css/main.css';
import './NavigationBar.css';
import { Link, useNavigate } from "react-router-dom";
import { auth } from '../../firebase-config';
import { useContext, useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import { AppContext } from '../../AppContext';

const Navigation = ({sideMenuToggle, isLoggedIn}) => {
    const [login, setLogin] = useState("login");
    const context = useContext(AppContext);
    const navigate = useNavigate();

    function logout(e){
        if(login === 'logout') {
            e.preventDefault();
            context.setLoading(true);
            signOut(auth).then().catch((error)=>{
                context.setAlertMessage(error)
            })
            localStorage.clear();
            navigate('/');
            window.location.reload();
        }
    }

    useEffect(()=>{
        if(isLoggedIn){
            setLogin("logout");
        } else {
            setLogin("login");
        }
    }, [isLoggedIn]);

    return (
        <div>
            <div className="top-bar color-scheme-bright">
            <div className="logo-w menu-size">
            <Link to="/">
                <div className="logo-label">
                    <i className="os-icon os-icon-robot-2"></i>
                </div>
            </Link>
            </div>
            <div className="top-menu-controls">
            <div onClick={sideMenuToggle} className="sidemenu-mobile-toggle">
                <i className="os-icon os-icon-menu"></i>
            </div>
            <div className="logged-user-w">
                <div className="logged-user-i">
                <div className="avatar-w">
                    <i className="os-icon os-icon-user"></i>
                </div>
                <div className="logged-user-menu color-style-bright">
                    <div className="logged-user-avatar-info">
                    <div className="avatar-w">
                        <i className="os-icon os-icon-user"></i>
                    </div>
                    <div className="logged-user-info-w">
                        <div className="logged-user-role">
                        Administrator
                        </div>
                    </div>
                    </div>
                    <ul>
                    <li>
                        {login === 'login'? 
                        <Link to='/forgot-password'><span><i className="os-icon os-icon-lock"></i>Forgot password</span></Link> : 
                        <Link to='/forgot-password'><span><i className="os-icon os-icon-lock"></i>Change password</span></Link> }
                        
                    </li>
                    <li>
                        <Link to={`/${login}`} onClick={logout}><i className="os-icon os-icon-signs-11"></i><span>{`${login.charAt(0).toUpperCase() + login.slice(1)}`}</span></Link>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    )
}

export default Navigation;