import { useNavigate } from "react-router-dom";
import './ProjectCard.css';

const ProjectCard = (props) => {
    const navigate = useNavigate();
    const goToPage = () => {
        if(props.projectHostName) {
            navigate(`/projects/domain/${props.projectName}`, {state: {projectName: props.projectName, projectId: props.projectId, siteId: props.siteId}});
        } else {
            navigate(`/projects/${props.projectName}`, {state: {projectName: props.projectName, siteId: props.projectId}});
        }
    }

    return (
        <div className="project-box" onClick={() => goToPage()}>
            <div className={`project-head ${props.class !== undefined ? props.class : ''}`}>
                <div className="project-title">
                <h5>
                    {props.class === 'domain-card' ? 
                    (props.projectName.replace('https://', '').replace(`${props.projectHostName}`, '') === '/' || props.projectName.replace('https://', '').replace(`${props.projectHostName}`, '') === '' ? 'Homepage' : props.projectName.replace('https://', '').replace(`${props.projectHostName}`, '') ) : 
                    props.projectName}
                </h5>
                </div>
            </div>

            {props.projectDomainNumber !== undefined ? (
                <div className="project-info">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="row">
                        <div className="col-12">
                            <div className="el-tablo highlight centered">
                            <div className="label centered">
                                Domains
                            </div>
                            <div className="value">
                                {props.projectDomainNumber}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            ) : ''}
        </div>  
    )
}

export default ProjectCard;