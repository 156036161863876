import axios from 'axios';
import { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../AppContext';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../firebase-config';

const TestingReason = ({reason, entryID}) => {
    const [reasonText, setUpdatedText] = useState(reason || '');
    const [userToken, setUserToken] = useState();
    const context = useContext(AppContext);

    useEffect(()=>{
        onAuthStateChanged(auth,(user)=>{
          user.getIdToken(false).then(function(idToken) {
            setUserToken(idToken);
          }).catch(function(error) {
              //
          });
        });
      }, [context.loading])

    function updateTesting(){
        axios.put(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains/${entryID}/reason.json?auth=${userToken}`, {reasonText})
        .then(response => {
            context.setAlertMessage("Testing reason updated successfully!");
            context.setLoading(true);
        })
        }

    return (
        <div className="testing-reason-div">
            <input className="form-control w-1/2" onChange={(e)=>setUpdatedText(e.target.value)} value={reasonText} placeholder="Not testing..."/>
            <button className="btn btn-primary" onClick={updateTesting}><i className="os-icon os-icon-save"></i></button>
        </div>
    )
}

export default TestingReason;