const TableRowError = ({url, message}) => {
    if(url !== undefined) {
        if(message !== 'N/A' ) {
            return (
                <tr key={url}>
                    <td><i className="os-icon os-icon-link-2"></i> <a href={url} target="_blank" rel="noreferrer">{url}</a>
                    </td>
                    <td className="text-center">
                        {message}
                    </td>
                </tr>
            )
        }
    }

    return null;
}

export default TableRowError;