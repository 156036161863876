import axios from "axios";
import { useEffect, useState } from "react";
import EditDomain from "../../Forms/EditDomain/EditDomain";
import TestingReason from "../../Forms/TestingInput/TestingInput";

const TableRow = ({url, site, reason, testing, checkIfSiteIsAccessible, checkSSL, isItIndexed, deleteDomain, userToken, currentPage}) => {
    const [htaccessValue, setHtaccessValue] = useState(false);
    const [sslValue, setSslValue] = useState(false);
    const [indexValue, setIndexValue] = useState(false);
    const [editURL, setEditURL] = useState(false);
    const [htaccessLoading, setHtaccessLoading] = useState(false);
    const [sslLoading, setSslLoading] = useState(false);
    const [indexLoading, setIndexLoading] = useState(false);

    const htaccessCheckmark = (siteID) => {
        if(htaccessValue === true) {
            axios.put(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains/${siteID}/testing/htaccess_enabled.json?auth=${userToken}`, "false");
            setHtaccessValue(false);
        } else {
            axios.put(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains/${siteID}/testing/htaccess_enabled.json?auth=${userToken}`, "true");
            setHtaccessValue(true);
        }
    }
    const sslCheckmark = (siteID) => {
        if(sslValue === true) {
            axios.put(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains/${siteID}/testing/ssl_enabled.json?auth=${userToken}`, "false");
            setSslValue(false);
        } else {
            axios.put(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains/${siteID}/testing/ssl_enabled.json?auth=${userToken}`, "true");
            setSslValue(true);
        }
    }
    const indexCheckmark = (siteID) => {
        if(indexValue === true) {
            axios.put(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains/${siteID}/testing/index_enabled.json?auth=${userToken}`, "false");
            setIndexValue(false);
        } else {
            axios.put(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains/${siteID}/testing/index_enabled.json?auth=${userToken}`, "true");
            setIndexValue(true);
        }
    }

    const runHtaccess = (url, reason) => {
        checkIfSiteIsAccessible(url, reason);
        setHtaccessLoading(true);
        setTimeout(()=>{
            setHtaccessLoading(false);
        }, 5000)
    }

    const runSSL = (url, reason) => {
        checkSSL(url, reason);
        setSslLoading(true);
        setTimeout(()=>{
            setSslLoading(false);
        }, 5000)
    }

    const runIndex = (url, reason) => {
        isItIndexed(url, reason);
        setIndexLoading(true);
        setTimeout(()=>{
            setIndexLoading(false);
        }, 5000)
    }

    useEffect(()=>{
        axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains/${site}.json?auth=${userToken}`).then((response)=> {
            setHtaccessValue(response.data.testing.htaccess_enabled);
            setSslValue(response.data.testing.ssl_enabled);
            setIndexValue(response.data.testing.index_enabled);
            });
      }, [userToken, editURL]);

    if(url !== undefined) {
        return (
            <tr key={url}>
                <td className="url-table-data">
                    <EditDomain
                        editURL={editURL}
                        url={url}
                        site={site}
                        userToken={userToken}
                        setEditURL={setEditURL}
                    />
                    <div>
                        <button className="btn btn-white" onClick={()=>setEditURL(!editURL)}><i className="os-icon os-icon-ui-49"></i></button>
                    </div>
                </td>
                <td className="text-center">
                    <TestingReason entryID={site} reason={reason}/>
                </td>
                <td className="text-center">
                    {htaccessValue ?
                    <div className="tool-container">
                        <input 
                            type="checkbox" 
                            id="htaccess-check" 
                            name="htaccess" 
                            value={true}
                            checked={true}
                            onChange={()=>htaccessCheckmark(site)}/> 
                        <button id="htaccess-btn" className="btn btn-secondary" disabled={htaccessLoading || false} type="button" onClick={()=>runHtaccess(url, reason)}>
                            <span className={`button-is-loading-${htaccessLoading}`}><i className="os-icon os-icon-shield"></i> Htaccess</span>
                            <i className={`os-icon os-icon-refresh-cw is-loading-${htaccessLoading} button-show-${htaccessLoading}`}></i>
                        </button>
                    </div> : 
                    <div className="tool-container">
                    <input 
                        type="checkbox" 
                        id="htaccess-check" 
                        name="htaccess" 
                        value={false}
                        checked={false}
                        onChange={()=>htaccessCheckmark(site)}/> 
                    <button id="htaccess-btn" className="btn btn-secondary" disabled={true} type="button" onClick={()=>runHtaccess(url, reason)}><i className="os-icon os-icon-shield"></i> Htaccess</button>
                </div>}
                    
                </td>
                <td className="text-center">
                    {sslValue ? 
                    <div className="tool-container">
                        <input 
                            type="checkbox" 
                            id="ssl-check" 
                            name="ssl" 
                            value={true}
                            checked={true}
                            onChange={()=>sslCheckmark(site)}/>
                        <button id="ssl-btn" className="btn btn-secondary" type="button" disabled={sslLoading || false} onClick={()=>runSSL(url, reason)}>
                            <span className={`button-is-loading-${sslLoading}`}><i className="os-icon os-icon-unlock"></i> SSL</span>
                            <i className={`os-icon os-icon-refresh-cw is-loading-${sslLoading} button-show-${sslLoading}`}></i>
                        </button>
                    </div> : 
                     <div className="tool-container">
                     <input 
                         type="checkbox" 
                         id="ssl-check" 
                         name="ssl" 
                         value={false}
                         checked={false}
                         onChange={()=>sslCheckmark(site)}/>
                     <button id="ssl-btn" className="btn btn-secondary" type="button" disabled={true} onClick={()=>runSSL(url, reason)}><i className="os-icon os-icon-unlock"></i> SSL</button>
                 </div>}
                </td>
                <td className="text-center">
                    {indexValue ? 
                    <div className="tool-container">
                        <input 
                            type="checkbox" 
                            id="index-check" 
                            name="index" 
                            value={true}
                            checked={true}
                            onChange={()=>indexCheckmark(site)}/>
                        
                        <button id="index-btn" className="btn btn-secondary" type="button" disabled={indexLoading || false} onClick={()=>runIndex(url, reason)}>
                            <span className={`button-is-loading-${indexLoading}`}><i className="os-icon os-icon-globe"></i> Index</span>
                            <i className={`os-icon os-icon-refresh-cw is-loading-${indexLoading} button-show-${indexLoading}`}></i>
                        </button>
                    </div>
                    : <div className="tool-container">
                        <input 
                            type="checkbox" 
                            id="index-check" 
                            name="index" 
                            value={false}
                            checked={false}
                            onChange={()=>indexCheckmark(site)}/>
                        
                        <button id="index-btn" className="btn btn-secondary" type="button" disabled={true} onClick={()=>runIndex(url, reason)}><i className="os-icon os-icon-globe"></i> Index</button>
                    </div>}           
                </td>
                <td className="text-center">
                    <button id="delete-btn" className="btn btn-danger" type="button" onClick={()=>deleteDomain(url)}><i className="os-icon os-icon-ui-15"></i></button>
                </td>
            </tr>
        )
    }
    return null;
}

export default TableRow;