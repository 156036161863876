import { sendPasswordResetEmail } from "firebase/auth";
import React, {useContext, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from '../../../firebase-config';
import { AppContext } from "../../../AppContext";
import '../Login/Login.css';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const context = useContext(AppContext);
  const navigate = useNavigate();

  function login(e){
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
    .then(() => {
        context.setAlertMessage("Please check your email...");
        setEmail('')
        navigate('/redirect');
    }).catch((e) => {
        context.setAlertMessage(e);
    }) 
  }

  return (
      <div className="auth-box-w">
      <div className="logo-w">
      <i className="logo-register-form os-icon os-icon-robot-2"></i>
      </div>
      <h4 className="auth-header">
          {localStorage.getItem('userLogin') !== null ? 'Change your password.' : 'Forgot your password?'}
      </h4>
      <form onSubmit={login}>
        <div className="form-group">
          <label htmlFor="">Email</label>
          <input 
            className="form-control" 
            placeholder="Enter your email" 
            type="email"
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
            />
          <div className="pre-icon os-icon os-icon-user-male-circle"></div>
        </div>
        <div className="buttons-w">
          <button className="btn btn-primary">Request new password</button>
        </div>
        {localStorage.getItem('userLogin') !== null ? '' : 
        <div>
            <Link to="/register">
                <p className="register-link">Need an account? Register here</p>
            </Link>
            <Link to="/login">
                <p className="forgot-password-link">Have an account? Login here</p>
            </Link>
        </div>
        }
      </form>
    </div>
  );

}

export default ForgotPassword;