const TableRowWarning = ({url, message}) => {
    if(url !== undefined) {
        return (
            <tr key={url}>
                <td><i className="os-icon os-icon-link-2"></i> <a href={url} target="_blank" rel="noreferrer">{url}</a>
                </td>
                <td className="text-center">
                    {message !== undefined && message.htaccess.warning_on_off === true ? `${message.htaccess.warning_reason}\n` : ''}
                    {message !== undefined && message.ssl.warning_on_off === true ? `${message.ssl.warning_reason}\n`: ''}
                    {message !== undefined && message.index.warning_on_off === true ? `${message.index.warning_reason}\n`: ''}
                </td>
            </tr>
        )
    }
    return null;
}

export default TableRowWarning;