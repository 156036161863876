import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import './ProjectsDashboard.css';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase-config';
import { AppContext } from '../../AppContext';

const ProjectsDashboard = () => {
    const context = useContext(AppContext)
    const [projects, setProjects] = useState([]);
    const [newProject, setNewProject] = useState('');
    const [userToken, setUserToken] = useState('');
    const [updatePage, setUpdatePage] = useState(false);
    const [finalValue, setFinalValue] = useState(null);
    const [noDomains, setNoDomains] = useState(false);


    function setSearchValue(value) {
      let searchListArray=[];
      let obj = {};
      Object.keys(projects).map((el) => {
        if(value === '') {
          obj[el] = projects[el];
          searchListArray.push(obj[el]);
        } else if(projects[el].details.projectName && projects[el].details.projectName !== undefined ? projects[el].details.projectName.toLowerCase().includes(value) : '') {
          obj[el] = projects[el];
          searchListArray.push(obj[el]);
        }
      })
      setFinalValue(searchListArray);
      if(searchListArray === undefined) {
        setNoDomains(true);
      } else {
        setNoDomains(false);
      }
    }

    const addNewProject = () => {
        if(newProject !== '' && newProject !== undefined && newProject !== null) {
            axios.post(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/projects.json?auth=${userToken}`, {
                projectName: newProject,
                domains: {},
                performanceReport: {}
            })
            .then(response => {
                setNewProject('');
                setUpdatePage(!updatePage);
                context.setAlertMessage('Project added successfully.')
            })
        } else {
            context.setAlertMessage('Please enter a project name.')
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth,(user)=>{
            user.getIdToken(true).then(function(idToken) {
                setUserToken(idToken);
                axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/projects.json?auth=${idToken}`).then((response)=> {
                    const data = response.data;
                    let projectArray = [];
                    Object.entries(data).forEach(item => {
                        projectArray.push({id: item[0], details: item[1]})
                    })
                    setProjects(projectArray);
                });
            }).catch(function(error) {
                //
            });
        });
    }, [finalValue, updatePage]);

    return (
        <div className="domain-container">
            <div style={{width: "100%"}} className="element-wrapper mt-2 pb-0">
                <div className='dashboard-header-container'>
                    <div>
                        <h6 className="dashboard-h6">
                            Performance Dashboard
                        </h6>
                        <h3 className="element-header ml-2 mb-2">Projects</h3>
                    </div>
                    <div><input className="form-control" placeholder="Enter new project" type="text" value={newProject} onChange={e=>setNewProject(e.target.value)}/></div>
                    <div><button className="btn btn-primary" onClick={()=>addNewProject()}><i className="os-icon os-icon-ui-22"></i><span>Create project</span></button></div>
                </div>
            </div>
            <div className='search-projects-container'>
                <h6 className="form-header">Search...</h6>
                <div className="input-group mb-4">
                <div className="input-group-prepend">
                    <div className="input-group-text">
                    <i className="os-icon os-icon-search"></i>
                    </div>
                </div>
                <input className="form-control" placeholder="Start typing to search..." type="text" onChange={(e)=>setSearchValue(e.target.value.toLowerCase())}/>
                </div>
            </div>
            <div className='projects-list'>
            {noDomains ? (<p>No results...</p>) : finalValue?.length !== 0 && projects?.length !== 0 ? (finalValue || projects).map((site, idx) => {
                if(site.details.projectName) {
                    return <ProjectCard 
                        key={idx}
                        projectName={site.details.projectName}
                        projectId={site.id}
                        projectDomainNumber={site.details.domains ? Object.entries(site.details.domains).length : 0}
                    />
                } 
            }) : (<p>No results...</p>)}
            </div>
        </div>
    )
}

export default ProjectsDashboard;