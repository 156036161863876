import React, { useState } from 'react';

export const AppContext = React.createContext(null);

const AppContextWrapper = (props) => {
	const [ loading, setLoading ] = useState(true);
    const [ message, setAlertMessage ] = useState('');
    const [ numberOfDomains, setNumberOfDomains] = useState("0");
	const [ isLoggedIn, setLoggedIn ] = useState(false);
	const [ domainAdded, setDomainAdded ] = useState(false); 
	const [ domainUpdated, setDomainUpdated ] = useState(false); 
	
	return (
		<AppContext.Provider 
			value={{ 
				loading, 
				setLoading, 
				message, 
				setAlertMessage, 
				numberOfDomains, 
				setNumberOfDomains, 
				isLoggedIn, 
				setLoggedIn,
				domainAdded,
				setDomainAdded,
				domainUpdated,
				setDomainUpdated
				}}>
			{props.children}
		</AppContext.Provider>
	);
}

export default AppContextWrapper;