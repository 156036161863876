import { React, useState, useEffect } from "react";
import '../../assets/css/index.css';
import '../Table/Table.css';
import TableRowError from "./TableRow/TableRowError";
import TableRowWarning from "./TableRow/TableRowWarning";

const Table = (props) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [dataLimit] = useState(20);
    const [pageLimit] = useState(5);
    const [finalValue, setFinalValue] = useState(null);
    const [noDomains, setNoDomains] = useState(false);
    const [pages, setPages] = useState(Math.ceil(Object.entries(finalValue || props.domainList).length / dataLimit));

    useEffect(()=>{
      setPages(Math.ceil(Object.entries(finalValue || props.domainList).length / dataLimit));
    }, [])

    function getPaginationGroup() {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
    };

    function goToNextPage(){
        setCurrentPage((page) => page + 1);
    };

    function goToPreviousPage() {
        setCurrentPage((page) => page - 1);
    };

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber);
    };

    function getPaginatedData() {
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return Object.keys(finalValue || props.domainList).slice(startIndex, endIndex);
    };

    function setSearchValue(value) {
      let searchListArray=[];
      let obj = {};
      Object.keys(props.domainList).map((el) => {
        if(value === '') {
          obj[el] = props.domainList[el];
          searchListArray.push(obj);
        } else if(props.domainList[el].url ? props.domainList[el].url.includes(value): '') {
          obj[el] = props.domainList[el];
          searchListArray.push(obj);
        }
      })
      setFinalValue(searchListArray[0]);
      if(searchListArray[0] === undefined) {
        setNoDomains(true);
      } else {
        setNoDomains(false);
      }
      setPages(Math.round(Object.keys(searchListArray[0]).length / dataLimit) + 1);
      setCurrentPage(1);
    }

    if(props.domainList) {
    return (
      <div className="element-box table-element-box">
        <h6 className="form-header">Search...</h6>
        <div className="input-group mb-4">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="os-icon os-icon-search"></i>
            </div>
          </div>
          <input className="form-control" placeholder="Start typing to search..." type="text" onChange={(e)=>setSearchValue(e.target.value)}/>
        </div>
        <div className="table-responsive">
        <table className="table table-lightborder">
            <thead>
            <tr>
                <th>
                Site
                </th>
                <th className="text-center">
                {props.warningOrError === 'warning' ? 'Warning' : 'Error'}
                </th>
            </tr>
            </thead>
            <tbody>
              {noDomains ? (<tr><td>No domains for that search query...</td></tr>) : getPaginatedData().map((site, idx) => (
              (props.warningOrError === 'warning' ? 
              <TableRowWarning 
                key={idx}
                url={props.domainList[site] !== undefined ? props.domainList[site].url : undefined} 
                site={site} 
                message={(props.domainList[site].warning ? props.domainList[site].warning : 'N/A')}
              /> : 
              (<>

              <TableRowError 
                key={idx}
                url={props.domainList[site] !== undefined ? props.domainList[site].url : undefined} 
                site={site} 
                message={(props.domainList[site].error ? (props.domainList[site].error.htaccess !== undefined && props.domainList[site].error.htaccess.error_on_off === true && props.domainList[site].error.index.error_on_off === true  ? props.domainList[site].error.htaccess.error_reason +", "+ props.domainList[site].error.index.error_reason : 'N/A') : 'N/A')}
              />

              <TableRowError 
                key={idx}
                url={props.domainList[site] !== undefined ? props.domainList[site].url : undefined} 
                site={site} 
                message={(props.domainList[site].error ? (props.domainList[site].error.htaccess !== undefined && props.domainList[site].error.htaccess.error_on_off === true && props.domainList[site].error.index.error_on_off === false  ? props.domainList[site].error.htaccess.error_reason : 'N/A') : 'N/A')}
              />

              <TableRowError 
                key={idx}
                url={props.domainList[site] !== undefined ? props.domainList[site].url : undefined} 
                site={site} 
                message={(props.domainList[site].error ? (props.domainList[site].error.ssl !== undefined && props.domainList[site].error.ssl.error_on_off === true ? props.domainList[site].error.ssl.error_reason : 'N/A') : 'N/A')}
              />
              
              <TableRowError 
                key={idx}
                url={props.domainList[site] !== undefined ? props.domainList[site].url : undefined} 
                site={site} 
                message={(props.domainList[site].error ? (props.domainList[site].error.index !== undefined && props.domainList[site].error.index.error_on_off === true && props.domainList[site].error.htaccess.error_on_off === false ? props.domainList[site].error.index.error_reason : 'N/A') : 'N/A')}
              /></>) )
            ))}
            </tbody>
        </table>
        </div>
        <div className="pagination">
          <button onClick={goToPreviousPage} className={`prev ${currentPage === 1 ? 'disabled' : ''}`}><i className="os-icon os-icon-chevron-left"></i></button>
          {getPaginationGroup().map((item, index) => (
          <button
              key={index}
              onClick={changePage}
              className={`paginationItem ${currentPage === item ? 'active' : null}`}
            ><span>{item}</span>
            </button>
          ))}
          <button
            onClick={goToNextPage}
            className={`next ${currentPage === pages ? 'disabled' : ''}`}
          ><i className="os-icon os-icon-chevron-right"></i></button>
        </div>
        </div>
    )}

    return (
        <div>
            No domains...
        </div>
    )
}

export default Table;