import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../AppContext";
import axios from 'axios';
import Table from "../../components/WarningErrorTable/Table";
import Loader from "../../components/Loader/Loader";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase-config';

const ErrorsDashboard = () => {
    const context = useContext(AppContext);
    const [domainList, setDomainList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        onAuthStateChanged(auth,(user)=>{
            user.getIdToken(true).then(function(idToken) {
                axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains.json?auth=${idToken}`).then((response)=> {
                    let domainsWithErrors = [];
                    Object.entries(response.data).map((item)=>{
                        if(item[1].error && item[1].error.htaccess && item[1].error.htaccess.error_reason !== undefined && item[1].error.htaccess.error_reason !== '') {
                            domainsWithErrors.push(...item);
                        } else if(item[1].error && item[1].error.ssl && item[1].error.ssl.error_reason !== undefined && item[1].error.ssl.error_reason !== '') {
                            domainsWithErrors.push(...item);
                        } else if(item[1].error && item[1].error.index && item[1].error.index.error_reason !== undefined && item[1].error.index.error_reason !== '') {
                            domainsWithErrors.push(...item);
                        }
                    })
                    setDomainList(domainsWithErrors);
                    domainsWithErrors.map(item => {
                        if(typeof item === 'object') console.log(item);
                    })
                    if(context.domainAdded) {
                        setLoading(true);
                        context.setDomainAdded(false);
                    }
                    setLoading(false);
                    context.setLoading(false);
                    context.setNumberOfDomains(domainList.length);
                });
            }).catch(function(error) {
                //
            });
        });
      }, [context.loading, loading, context.domainAdded]);

    return (
        <div className="domain-container">
            <div style={{width: "100%"}} className="element-wrapper mt-2 pb-0">
                <div>
                    <h6 className="element-header ml-2 mb-2">
                    Domains with errors
                    </h6>
                </div>
            </div>
            {loading ? 
            <Loader /> : 
            <Table 
                warningOrError={'error'}
                domainList={domainList}
            />
            }
        </div>
    )
}

export default ErrorsDashboard;