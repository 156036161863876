import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

const PerformanceChart = (props) => {
    const [typeOfChart, setTypeOfChart] = useState("LineChart")
    const options = {
        title: "Performance Chart",
        legend: { position: "bottom" },
        hAxis: {
            title: "Date",
        },
        vAxis: {
            title: "Score",
        },
        colors: ['#1b55e2'],
    };

    useEffect(()=>{

    }, [typeOfChart])

    return (
        <div className="chart-container">
            <div className="chart-select-form">
                <select className="form-control form-control-sm mb-2" value={typeOfChart} onChange={(e)=>setTypeOfChart(e.target.value)}>
                    <option value="LineChart">Line Chart</option>
                    <option value="ColumnChart">Column Chart</option>
                    <option value="ScatterChart">Scatter Chart</option>
                    <option value="SteppedAreaChart">Stepped Area Chart</option>
                </select>
            </div>
            <Chart
            chartType={typeOfChart}
            data={props.data}
            options={options}
            width="100%"
            height="500px"
            />
        </div>
    )
}

export default PerformanceChart;