import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import './TestingSchedule.css';
import axios from 'axios';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase-config';
import { AppContext } from '../../AppContext';
import { useLocation } from 'react-router-dom';

const TestingSchedule = (props) => {
    const { state } = useLocation();
    const [days, setDays] = useState([]);
    const [hoursAM, setHoursAM] = useState([]);
    const [hoursPM, setHoursPM] = useState([]);
    const [userToken, setUserToken] = useState();
    const [emptyDayField, setEmptyDayField] = useState(false);
    const [emptyHoursAMField, setEmptyHoursAMField] = useState(false);
    const [emptyHoursPMField, setEmptyHoursPMField] = useState(false);
    const context = useContext(AppContext);

    const dayOptions = [
        {value: "1", label: "Monday"},
        {value: "2", label: "Tuesday"},
        {value: "3", label: "Wednesday"},
        {value: "4", label: "Thursday"},
        {value: "5", label: "Friday"},
        {value: "6", label: "Saturday"},
        {value: "0", label: "Sunday"},
    ]

    const AMHourOptions = [
        {value: "0", label: "00:00"},
        {value: "1", label: "01:00"},
        {value: "2", label: "02:00"},
        {value: "3", label: "03:00"},
        {value: "4", label: "04:00"},
        {value: "5", label: "05:00"},
        {value: "6", label: "06:00"},
        {value: "7", label: "07:00"},
        {value: "8", label: "08:00"},
        {value: "9", label: "09:00"},
        {value: "10", label: "10:00"},
        {value: "11", label: "11:00"},
    ]

    const PMHourOptions = [
        {value: "12", label: "12:00"},
        {value: "13", label: "13:00"},
        {value: "14", label: "14:00"},
        {value: "15", label: "15:00"},
        {value: "16", label: "16:00"},
        {value: "17", label: "17:00"},
        {value: "18", label: "18:00"},
        {value: "19", label: "19:00"},
        {value: "20", label: "20:00"},
        {value: "21", label: "21:00"},
        {value: "22", label: "22:00"},
        {value: "23", label: "23:00"},
    ]

    const saveTestingSchedule = () => {
        axios.put(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/projects/${props.state.siteId}/domains/${props.state.projectId}/schedule.json?auth=${userToken}`, {
            days: days,
            hoursAM: hoursAM,
            hoursPM: hoursPM
        }).then(response => {
            if(response.status === 200) {
                axios.get(`https://devbot-server.herokuapp.com/save-test?websiteId=${props.state.projectId}&projectId=${props.state.siteId}&url=${props.state.projectName}`);
                context.setAlertMessage("Saved changes.");
            } else {
                context.setAlertMessage("Something went wrong. Please try again later!");
            }
        })
    }

    useEffect(()=>{
        onAuthStateChanged(auth,(user)=>{
            user.getIdToken(true).then(function(idToken) {
                setUserToken(idToken);
                axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/projects/${props.state.siteId}/domains/${props.state.projectId}/schedule.json?auth=${idToken}`)
                .then(response => {
                    let theDays = response.data.days;
                    let theAMHours = response.data.hoursAM;
                    let thePMHours = response.data.hoursPM;

                    if(days.length === 0 && emptyDayField === false) {
                        setEmptyDayField(true);
                        setDays(theDays);
                    }
                    if(hoursAM.length === 0 && emptyHoursAMField === false) {
                        setEmptyHoursAMField(true);
                        setHoursAM(theAMHours);
                    }
                    if(hoursPM.length === 0 && emptyHoursPMField === false) {
                        setEmptyHoursPMField(true);
                        setHoursPM(thePMHours);
                    }

                })
            }).catch(function(error) {
                //
            });
        });
    }, [days, hoursAM, hoursPM])

    return (
        <div className="element-box-content mb-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-4">
                        <p>Day</p>
                        <Select
                            isMulti
                            name="colors"
                            options={dayOptions}
                            value={days}
                            onChange={setDays}
                            className="basic-multi-select"
                            classNamePrefix="select"
                        />
                    </div>
                    <div className="col-4">
                    <p>AM Hours</p>
                    <Select
                        isMulti
                        name="colors"
                        options={AMHourOptions}
                        value={hoursAM}
                        onChange={setHoursAM}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                    </div>
                    <div className="col-4">
                    <p>PM Hours</p>
                    <Select
                        isMulti
                        name="colors"
                        options={PMHourOptions}
                        value={hoursPM}
                        onChange={setHoursPM}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                    </div>
                </div>
            </div>
            <button className='mr-2 mb-2 mt-4 ml-0 btn btn-primary' onClick={()=>saveTestingSchedule()}>Save changes</button>
        </div>
    )
}

export default TestingSchedule;