import './Dashboard.css';
import InfoBox from '../../components/InfoBox/InfoBox';
import Loader from '../../components/Loader/Loader';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {AppContext} from '../../AppContext';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase-config';

const Dashboard = (props) => {
    const context = useContext(AppContext);
    const [numberOfDomains, setNumberOfDomains] = useState(0);
    const [nrDomainsWithWarnings, setNrDomainsWithWarnings] = useState(0);
    const [nrDomainsWithErrors, setNrDomainsWithErrors] = useState(0);

    useEffect(()=>{
        onAuthStateChanged(auth,(user)=>{
            user.getIdToken(true).then(function(idToken) {
                axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains.json?auth=${idToken}`).then((response)=> {
                    let domainsWithWarnings = [];
                    let domainsWithErrors = [];
                    Object.entries(response.data).map((item)=>{
                        if(item[1].warning && item[1].warning.htaccess && item[1].warning.htaccess.warning_reason !== undefined && item[1].warning.htaccess.warning_reason !== '') {
                            domainsWithWarnings.push(...item);
                        }
                        if(item[1].warning && item[1].warning.ssl && item[1].warning.ssl.warning_reason !== undefined && item[1].warning.ssl.warning_reason !== '') {
                            domainsWithWarnings.push(...item);
                        }
                        if(item[1].warning && item[1].warning.index && item[1].warning.index.warning_reason !== undefined && item[1].warning.index.warning_reason !== '') {
                            domainsWithWarnings.push(...item);
                        }
                        if(item[1].error && item[1].error.htaccess && item[1].error.htaccess.error_reason !== undefined && item[1].error.htaccess.error_reason !== '') {
                            domainsWithErrors.push(...item);
                        }
                        if(item[1].error && item[1].error.ssl && item[1].error.ssl.error_reason !== undefined && item[1].error.ssl.error_reason !== '') {
                            domainsWithErrors.push(...item);
                        }
                        if(item[1].error && item[1].error.index && item[1].error.index.error_reason !== undefined && item[1].error.index.error_reason !== '') {
                            domainsWithErrors.push(...item);
                        }
                    })
                    setNrDomainsWithWarnings(domainsWithWarnings.length / 2);
                    setNrDomainsWithErrors(domainsWithErrors.length / 2);
                    const data = response.data;
                    context.setLoading(false);
                    setNumberOfDomains(Object.keys(data).length);
                });
            }).catch(function(error) {
                //
            });
        })
      }, [context.loading]);
    
    if(context.loading) {
        return <Loader />
    }
    return (
        <div className="dashboard-container">
            {props.title ? <h3>{props.title}</h3> : <h1>Main Dashboard</h1>}
            <div className="dashboard-box-container">
                <InfoBox boxTitle="Domains" type="domain-list" nrOfDomains={numberOfDomains} linkTo="/url-checks"/>
                <InfoBox boxTitle="Warnings" type="warnings" nrOfDomains={nrDomainsWithWarnings} linkTo="/urls-with-warnings"/>
                <InfoBox boxTitle="Errors" type="errors" nrOfDomains={nrDomainsWithErrors} linkTo="/urls-with-errors"/>
            </div>
        </div>
    );
}

export default Dashboard;