// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD9cMvyb69hnXGZoliDaxLgJqhFROMfqgc",
  authDomain: "devbot2-e1915.firebaseapp.com",
  databaseURL: "https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "devbot2-e1915",
  storageBucket: "devbot2-e1915.appspot.com",
  messagingSenderId: "54598638351",
  appId: "1:54598638351:web:3b0804441836a1cca7fc7a",
  measurementId: "G-WCP3KJHRM3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
export {auth}