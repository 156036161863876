import { useContext, useState } from 'react';
import React from 'react';
import NavigationBar from '../../components/NavigationBar/Navigation';
import SideMenu from '../../components/SideMenu/SideMenu';
import { AppContext } from '../../AppContext';
import Alert from '../../components/Alert/Alert';
import './Layout.css';

const Layout = ({children, isLoggedIn}) => {
    const [sideMenu, setSideMenuToggle] = useState(true);
    const context = useContext(AppContext);
    const sideMenuToggle = () =>{
        setSideMenuToggle(!sideMenu);
    }

    return (
        <>
            <NavigationBar sideMenuToggle={sideMenuToggle} isLoggedIn={isLoggedIn}/>
            <div className="main-container">
            <Alert message={context.message}/>
             <SideMenu toggle={sideMenu} stateChanger={setSideMenuToggle} isLoggedIn={isLoggedIn}/> 
                <div id="main-content-area" className="div-container">
                    {children}
                </div>
            </div>
        </>
    );
}

export default Layout;