import './App.css';
import { React, BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './containers/Dashboard/Dashboard';
import Layout from './containers/Layout/Layout';
import CheckDashboard from './containers/checkDashboard/CheckDashboard';
import AppContextWrapper from './AppContext';
import Register from './components/Forms/Register/Register';
import Login from './components/Forms/Login/Login';
import ForgotPassword from './components/Forms/ForgotPassword/ForgotPassword';
import Redirected from './components/Redirected/Redirected';
import { AuthProvider } from './AuthContext';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import {auth} from '../src/firebase-config';
import NotFound from './components/NotFound/NotFound';
import WarningDashboard from './containers/WarningDashboard/WarningDashboard';
import ErrorsDashboard from './containers/ErrorDashboard/ErrorsDashboard';
import ProjectsDashboard from './containers/ProjectsDashboard/ProjectsDashboard';
import ProjectPage from './containers/ProjectPage/ProjectPage';
import DomainPage from './containers/DomainPage/DomainPage';


const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(()=>{
    onAuthStateChanged(auth,(user)=>{
      setCurrentUser(user);
      if(user !== null){
        localStorage.setItem('authUser', 'true');
        setLoggedIn(true);
      }
    })
  }, [localStorage])

  let routes = (
    <Routes>
      <Route path="*" element={<NotFound/>}/>
      <Route path="/url-checks" element={localStorage.getItem('authUser') ? <CheckDashboard /> : <Navigate to="/login"/>}/>
      <Route path="/urls-with-warnings" element={localStorage.getItem('authUser') ? <WarningDashboard /> : <Navigate to="/login"/>}/>
      <Route path="/urls-with-errors" element={localStorage.getItem('authUser') ? <ErrorsDashboard /> : <Navigate to="/login"/>}/>
      <Route path="/projects" element={localStorage.getItem('authUser') ? <ProjectsDashboard /> : <Navigate to="/login"/>}/>
      <Route path="/projects/:id" element={localStorage.getItem('authUser') ? <ProjectPage /> : <Navigate to="/login"/>}/>
      <Route path="/projects/domain/*" element={localStorage.getItem('authUser') ? <DomainPage /> : <Navigate to="/login"/>}/>
      <Route path="/login" element={localStorage.getItem('authUser') ? <Dashboard /> : <Login/>}/>
      <Route path="/register" element={localStorage.getItem('authUser') ? <Dashboard /> : <Register/>}/>
      <Route path="/forgot-password" element={<ForgotPassword/>}/>
      <Route path="/redirect" element={<Redirected/>}/>
      <Route path="/" exact element={localStorage.getItem('authUser') ? <Dashboard /> : <Navigate to="/login"/>}/>
    </Routes>
  );

  return (
    <BrowserRouter>
      <AuthProvider value={{currentUser}}>
        <AppContextWrapper>
          <Layout isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn}>
            {routes}
          </Layout>
        </AppContextWrapper>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
