import { signInWithEmailAndPassword } from "firebase/auth";
import React, {useContext, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from '../../../firebase-config';
import { AppContext } from "../../../AppContext";
import './Login.css';
import Loader from "../../Loader/Loader";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const context = useContext(AppContext);

  function login(e){
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      localStorage.setItem("userLogin", "yes");
      navigate('/')
    })
    .catch(err => context.setAlertMessage(err.message))
  }

  if(localStorage.getItem('userLogin') !== null) {
    return <Loader/>
  }

  return (
      <div className="auth-box-w">
      <div className="logo-w">
      <i className="logo-register-form os-icon os-icon-robot-2"></i>
      </div>
      <h4 className="auth-header">
        Login Form
      </h4>
      <form onSubmit={login}>
        <div className="form-group">
          <label htmlFor="">Email</label>
          <input 
            className="form-control" 
            placeholder="Enter your email" 
            type="text"
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
            />
          <div className="pre-icon os-icon os-icon-user-male-circle"></div>
        </div>
        <div className="form-group">
          <label htmlFor="">Password</label>
          <input 
            className="form-control" 
            placeholder="Enter your password" 
            type="password"
            required
            value={password}
            onChange={e => setPassword(e.target.value)}
            />
          <div className="pre-icon os-icon os-icon-fingerprint"></div>
        </div>
        <div className="buttons-w">
          <button className="btn btn-primary">Log in</button>
        </div>
        <Link to="/register">
          <p className="register-link">Need an account? Register here</p>
        </Link>
        <Link to="/forgot-password">
          <p className="forgot-password-link">Forgot your password?</p>
        </Link>
      </form>
    </div>
  );

}

export default Login;