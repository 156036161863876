import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { auth } from '../../firebase-config';
import './PerformanceReport.css';
import Loader from '../Loader/Loader2';

const PerformanceReport = (props) => {
    const [userToken, setUserToken] = useState('');
    const [desktopResults, setDesktopResults] = useState({});
    const [mobileResults, setMobileResults] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        onAuthStateChanged(auth,(user)=>{
            user.getIdToken(true).then(function(idToken) {
                setUserToken(idToken);
                axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/projects/${props.state.siteId}.json?auth=${idToken}`)
                .then(response => {
                    let domains = response.data.domains
                    let desktopClsScoreAverage = [];
                    let desktopFcpScoreAverage = [];
                    let desktopLcpScoreAverage = [];
                    let desktopPerformanceScoreAverage = [];
                    let desktopSpeedIndexAverage = [];
                    let desktopTbtScoreAverage = [];
                    let desktopTtiScoreAverage = [];
                    let mobileClsScoreAverage = [];
                    let mobileFcpScoreAverage = [];
                    let mobileLcpScoreAverage = [];
                    let mobilePerformanceScoreAverage = [];
                    let mobileSpeedIndexAverage = [];
                    let mobileTbtScoreAverage = [];
                    let mobileTtiScoreAverage = [];
                    Object.entries(domains).map((domain)=>{
                        if(domain[1].logs !== undefined) {
                            Object.entries(domain[1].logs).map((log) => {
                                desktopClsScoreAverage.push(Number(log[1].desktopClsScore.value));
                                desktopFcpScoreAverage.push(Number(log[1].desktopFcpScore.value.replace("s", "")));
                                desktopLcpScoreAverage.push(Number(log[1].desktopLcpScore.value.replace("s", "")));
                                desktopPerformanceScoreAverage.push(log[1].desktopPerformanceScore);
                                desktopSpeedIndexAverage.push(Number(log[1].desktopSpeedIndex.value.replace("s", "")));
                                desktopTbtScoreAverage.push(Number(log[1].desktopTbtScore.value.replace("ms", "").replace(',','')));
                                desktopTtiScoreAverage.push(Number(log[1].desktopTtiScore.value.replace("s", "")));
                                mobileClsScoreAverage.push(Number(log[1].mobileClsScore.value));
                                mobileFcpScoreAverage.push(Number(log[1].mobileFcpScore.value.replace("s", "")));
                                mobileLcpScoreAverage.push(Number(log[1].mobileLcpScore.value.replace("s", "")));
                                mobilePerformanceScoreAverage.push(log[1].mobilePerformanceScore);
                                mobileSpeedIndexAverage.push(Number(log[1].mobileSpeedIndex.value.replace("s", "")));
                                mobileTbtScoreAverage.push(Number(log[1].mobileTbtScore.value.replace("ms", "").replace(',','')));
                                mobileTtiScoreAverage.push(Number(log[1].mobileTtiScore.value.replace("s", "")));
                            })
                        }
                    })
                    setDesktopResults({
                        clsAVG: (desktopClsScoreAverage.reduce((a,b)=>a+b,0) / desktopClsScoreAverage.length).toFixed(2),
                        fcpAVG: (desktopFcpScoreAverage.reduce((a,b)=>a+b,0) / desktopFcpScoreAverage.length).toFixed(2),
                        lcpAVG: (desktopLcpScoreAverage.reduce((a,b)=>a+b,0) / desktopLcpScoreAverage.length).toFixed(2),
                        performanceAVG: (desktopPerformanceScoreAverage.reduce((a,b)=>a+b,0) / desktopPerformanceScoreAverage.length).toFixed(2),
                        speedIndexAVG: (desktopSpeedIndexAverage.reduce((a,b)=>a+b,0) / desktopSpeedIndexAverage.length).toFixed(2),
                        tbtAVG: (desktopTbtScoreAverage.reduce((a,b)=>a+b,0) / desktopTbtScoreAverage.length).toFixed(2),
                        ttiAVG: (desktopTtiScoreAverage.reduce((a,b)=>a+b,0) / desktopTtiScoreAverage.length).toFixed(2),
                    })
                    setMobileResults({
                        clsAVG: (mobileClsScoreAverage.reduce((a,b)=>a+b,0) / mobileClsScoreAverage.length).toFixed(2),
                        fcpAVG: (mobileFcpScoreAverage.reduce((a,b)=>a+b,0) / mobileFcpScoreAverage.length).toFixed(2),
                        lcpAVG: (mobileLcpScoreAverage.reduce((a,b)=>a+b,0) / mobileLcpScoreAverage.length).toFixed(2),
                        performanceAVG: (mobilePerformanceScoreAverage.reduce((a,b)=>a+b,0) / mobilePerformanceScoreAverage.length).toFixed(2),
                        speedIndexAVG: (mobileSpeedIndexAverage.reduce((a,b)=>a+b,0) / mobileSpeedIndexAverage.length).toFixed(2),
                        tbtAVG: (mobileTbtScoreAverage.reduce((a,b)=>a+b,0) / mobileTbtScoreAverage.length).toFixed(2),
                        ttiAVG: (mobileTtiScoreAverage.reduce((a,b)=>a+b,0) / mobileTtiScoreAverage.length).toFixed(2),
                    })
                    // setLoading(false);
                })
            }).catch(function(error) {
                //
            });
        });
    }, [])

    return (
        <div className="row performance-report-box">
            <div className="col-sm-12">
                <div className="element-box">
                    <div className="element-info">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                        <div className="element-info-with-icon">
                            <div className="element-info-text">
                            <h5 className="element-inner-header">
                                {props.device === 'Desktop' ? 'Desktop' : 'Mobile'} Performance Statistics
                            </h5>
                            <div className="element-inner-desc">
                                Average performance scores of the last 7 days.
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-12 col-xxl-12">
                        <div className="padded">
                            <div className="el-tablo bigger">
                                <div className="value">
                                {props.device === 'Desktop' ? 
                                (desktopResults.performanceAVG ? (desktopResults.performanceAVG  >= 0.8 ? 'Good' : (desktopResults.performanceAVG  >= 0.6 && desktopResults.performanceAVG <= 0.79 ? 'Average' : 'Low')) : (loading ? <Loader/> : 'No data')) : 
                                (mobileResults.performanceAVG ? (mobileResults.performanceAVG  >= 0.8 ? 'Good' : (mobileResults.performanceAVG  >= 0.6 && mobileResults.performanceAVG <= 0.79 ? 'Average' : 'Low')) : (loading ? <Loader/> : 'No data'))} 
                                </div>
                                <div className={`trending ${props.device === 'Desktop' ? 
                                (desktopResults.performanceAVG  >= 0.8 ? 'good-overall-score' : (desktopResults.performanceAVG  >= 0.6 && desktopResults.performanceAVG <= 0.79 ? 'avg-overall-score' : 'bad-overall-score')) : 
                                (mobileResults.performanceAVG  >= 0.8 ? 'good-overall-score' : (mobileResults.performanceAVG  >= 0.6 && mobileResults.performanceAVG <= 0.79 ? 'avg-overall-score' : 'bad-overall-score'))}`}>
                                <span>{props.device === 'Desktop' ? (desktopResults.performanceAVG * 100).toFixed(0) : (mobileResults.performanceAVG * 100).toFixed(0)}/100</span>
                                </div>
                                <div className="label">
                                Overall Performance Score
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-lg-8">
                    <div className="tablos">
                        <div className="row mb-xl-2 mb-xxl-3">
                        <div className="col-xl-4">
                            <a className="element-box el-tablo centered trend-in-corner padded bold-label">
                            <div className="value">
                                {props.device === 'Desktop' ? (desktopResults.clsAVG ? desktopResults.clsAVG : (loading ? <Loader/> : 'No data')) : (mobileResults.clsAVG ? mobileResults.clsAVG : (loading ? <Loader/> : 'No data'))}
                            </div>
                            <div className="label">
                                Average CLS	Score
                            </div>

                            </a>
                        </div>
                        <div className="col-xl-4">
                            <a className="element-box el-tablo centered trend-in-corner padded bold-label">
                            <div className="value">
                                {props.device === 'Desktop' ? (desktopResults.lcpAVG ? desktopResults.lcpAVG : (loading ? <Loader/> : 'No data')) : (mobileResults.lcpAVG ? mobileResults.lcpAVG : (loading ? <Loader/> : 'No data'))} s
                            </div>
                            <div className="label">
                                Average LCP Score
                            </div>
                            </a>
                        </div>
                        <div className="col-xl-4">
                            <a className="element-box el-tablo centered trend-in-corner padded bold-label">
                            <div className="value">
                                {props.device === 'Desktop' ? (desktopResults.fcpAVG ? desktopResults.fcpAVG : (loading ? <Loader/> : 'No data')) : (mobileResults.fcpAVG ? mobileResults.fcpAVG : (loading ? <Loader/> : 'No data'))} s
                            </div>
                            <div className="label">
                                Average FCP Score
                            </div>
                            </a>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-xl-4">
                            <a className="element-box el-tablo centered trend-in-corner padded bold-label">
                            <div className="value">
                                {props.device === 'Desktop' ? (desktopResults.tbtAVG ? desktopResults.tbtAVG : (loading ? <Loader/> : 'No data')) : (mobileResults.tbtAVG ? mobileResults.tbtAVG : (loading ? <Loader/> : 'No data'))} ms
                            </div>
                            <div className="label">
                                Average TBT Score
                            </div>

                            </a>
                        </div>
                        <div className="col-xl-4">
                            <a className="element-box el-tablo centered trend-in-corner padded bold-label">
                            <div className="value">
                                {props.device === 'Desktop' ? (desktopResults.ttiAVG ? desktopResults.ttiAVG : (loading ? <Loader/> : 'No data')) : (mobileResults.ttiAVG ? mobileResults.ttiAVG : (loading ? <Loader/> : 'No data'))} s
                            </div>
                            <div className="label">
                                Average TTI Score
                            </div>
                            </a>
                        </div>
                        <div className="col-xl-4">
                            <a className="element-box el-tablo centered trend-in-corner padded bold-label">
                            <div className="value">
                                {props.device === 'Desktop' ? (desktopResults.speedIndexAVG ? desktopResults.speedIndexAVG : (loading ? <Loader/> : 'No data')) : (mobileResults.speedIndexAVG ? mobileResults.speedIndexAVG : (loading ? <Loader/> : 'No data'))} s
                            </div>
                            <div className="label">
                                Average Speed Index
                            </div>
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
    )
}

export default PerformanceReport;