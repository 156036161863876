import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../AppContext";
import axios from 'axios';
import Table from "../../components/WarningErrorTable/Table";
import Loader from "../../components/Loader/Loader";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase-config';

const WarningDashboard = () => {
    const context = useContext(AppContext);
    const [domainList, setDomainList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        onAuthStateChanged(auth,(user)=>{
            user.getIdToken(true).then(function(idToken) {
            axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains.json?auth=${idToken}`).then((response)=> {
                let domainsWithWarnings = [];
                Object.entries(response.data).map((item)=>{
                    if(item[1].warning && item[1].warning.htaccess && item[1].warning.htaccess.warning_reason !== undefined && item[1].warning.htaccess.warning_reason !== '') {
                        domainsWithWarnings.push(...item);
                    } else if(item[1].warning && item[1].warning.ssl && item[1].warning.ssl.warning_reason !== undefined && item[1].warning.ssl.warning_reason !== '') {
                        domainsWithWarnings.push(...item);
                    } else if(item[1].warning && item[1].warning.index && item[1].warning.index.warning_reason !== undefined && item[1].warning.index.warning_reason !== '') {
                        domainsWithWarnings.push(...item);
                    }
                })
                setDomainList(domainsWithWarnings);
                if(context.domainAdded) {
                    setLoading(true);
                    context.setDomainAdded(false);
                }
                setLoading(false);
                context.setLoading(false);
                setLoading(false)
                context.setNumberOfDomains(domainList.length);
            });
        }).catch(function(error) {
            //
        });
    });
      }, [context.loading, loading, context.domainAdded]);

    return (
        <div className="domain-container">
            <div style={{width: "100%"}} className="element-wrapper mt-2 pb-0">
                <div>
                    <h6 className="element-header ml-2 mb-2">
                    Domains with warnings
                    </h6>
                </div>
            </div>
            {loading ? 
            <Loader /> : 
            <Table 
                warningOrError={'warning'}
                domainList={domainList}
            />
            }
        </div>
    )
}

export default WarningDashboard;