import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import axios from 'axios';
import './AddDomain.css';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../../firebase-config';

const AddDomain = () => {
    const context = useContext(AppContext);
    const [domain, setDomain] = useState("");
    const [userToken, setUserToken] = useState();
    const [htaccessValue, setHtaccessValue] = useState(true);
    const [sslValue, setSSLValue] = useState(true);
    const [indexValue, setIndexValue] = useState(true);
    const [reasonText, setReasonText] = useState("");

    useEffect(()=>{
      onAuthStateChanged(auth,(user)=>{
        user.getIdToken(false).then(function(idToken) {
          setUserToken(idToken);
        }).catch(function(error) {
            //
        });
      });
    }, [context.loading, htaccessValue, sslValue, indexValue, reasonText])
  
    function addSite(){
      const domainInput = document.getElementById("domain-input");
      const testingInput = document.getElementById("testing-reason");
      let isInTheList = false;
      axios.get(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains.json?auth=${userToken}`).then((response)=> {
        const data = response.data;
        Object.entries(data).map((site) => {
            if(site[1].url === domain) {
                isInTheList = true;
            }
            return true;
            })
          if(domain.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g) && isInTheList === false){
            axios.post(`https://devbot2-e1915-default-rtdb.europe-west1.firebasedatabase.app/domains.json?auth=${userToken}`, {
              error: {
                htaccess: {
                  error_on_off: false,
                  error_reason: ''
                },
                ssl: {
                  error_on_off: false,
                  error_reason: ''
                },
                index: {
                  error_on_off: false,
                  error_reason: ''
                }
              },
              warning: {
                htaccess: {
                  warning_on_off: false,
                  warning_reason: ''
                },
                ssl: {
                  warning_on_off: false,
                  warning_reason: ''
                },
                index: {
                  warning_on_off: false,
                  warning_reason: ''
                }
              },
              reason: {
                reasonText: reasonText
              },
              testing: {
                htaccess_enabled: htaccessValue,
                ssl_enabled: sslValue,
                index_enabled: indexValue
              },
              url: domain
            })
            setDomain("");
            testingInput.value = "";
            setHtaccessValue(true);
            setIndexValue(true);
            setSSLValue(true);
            domainInput.value = "";
            context.setAlertMessage("Website added successfully!");
            context.setDomainAdded(true);
          } else if(domain === "") {
            context.setAlertMessage("Please enter a URL.");
          } else {
            context.setAlertMessage("The URL entered is either not correct or already on the list.");
          }
      });
    }

    return (
      <>
      <li className="sub-header">
        <span>Add Domain</span>
      </li>
      <li className="has-sub-menu">
        <input id="domain-input" className="form-control" placeholder="Enter new domain" type="text" onChange={e=>setDomain(e.target.value)}/>
        <input id="testing-reason" className="form-control mt-2" placeholder="Testing reason" type="text" onChange={e=>setReasonText(e.target.value)}/>
        <div className='domain-settings-container'>
        <label className='check-label'>
          {htaccessValue ? <input 
            type="checkbox" 
            id="htaccess-check" 
            name="htaccess" 
            value={true}
            checked={true}
            onChange={()=>setHtaccessValue(false)}/> : 
          <input 
            type="checkbox" 
            id="htaccess-check" 
            name="htaccess" 
            value={false}
            checked={false}
            onChange={()=>setHtaccessValue(true)}/>}
          &nbsp;Htaccess</label>
        <label className='check-label'>
          {sslValue ? <input 
            type="checkbox" 
            id="ssl-check" 
            name="ssl" 
            value={true}
            checked={true}
            onChange={()=>setSSLValue(false)}/> : 
          <input 
            type="checkbox" 
            id="ssl-check" 
            name="ssl" 
            value={false}
            checked={false}
            onChange={()=>setSSLValue(true)}/>}
        &nbsp;SSL</label>
          <label className='check-label'>
          {indexValue ? <input 
            type="checkbox" 
            id="index-check" 
            name="index" 
            value={true}
            checked={true}
            onChange={()=>setIndexValue(false)}/> : 
          <input 
            type="checkbox" 
            id="index-check" 
            name="index" 
            value={false}
            checked={false}
            onChange={()=>setIndexValue(true)}/>}
          &nbsp;Index</label>
        </div>
        <button className="button-full-width mb-4 btn btn-primary" type="button" onClick={addSite}>Add Domain <i className="os-icon os-icon-ui-22"></i></button>
      </li>
      </>
    )

}

export default AddDomain;